import { Component, Input, ViewChildren, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-pin-code',
  templateUrl: './pin-code.component.html',
  styleUrls: ['./pin-code.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PinCodeComponent),
      multi: true
    }
  ]
})
export class PinCodeComponent implements ControlValueAccessor {
  @Input('wrongCode') wrongCode: boolean = false;

  ngAfterViewInit() {
    this.digits._results[0].nativeElement.focus();
  }

  @Input('length') length: number = 4;

  data: string[] = Array(length).fill('');;

  @ViewChildren('digits') digits: any;

  _pinCode!: string;
  get pinCode(): string {
    return this._pinCode;
  }

  @Input('Model') set pinCode(val: string) {
    this._pinCode = val;
    this.onChange(val);
    if (val == '') {
      setTimeout(() => {
        this.digits._results[0].nativeElement.focus();
      });
    }
  }

  onChange: any = () => { };
  onTouch: any = () => { };
  writeValue(obj: string): void {
    if (obj == null) {
      this.data = Array(length).fill('');
      this.pinCode = '';
      return;
    }
    this.data = obj.split('');
    this.pinCode = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  onKeyDown(event: any, number: number) {
    if ((event.ctrlKey && event.key == 'v')) return;
    event.preventDefault();

    let intRegex = /^[a-zA-Z0-9]$/;

    if (intRegex.test(event.key)) {
      setTimeout(() => {
        this.data[number] = event.key.toUpperCase();
        if (number < this.length - 1) this.digits._results[number + 1].nativeElement.focus();
        this.onInputChange();
        this.setFocus(number + 1);
      });
    }
    else if (event.key == 'Backspace' || event.key == 'Delete') {
      setTimeout(() => {
        if (number > 0) {
          this.digits._results[number - 1].nativeElement.focus();
        }
        if (!this.data[number] && number > 0) this.data[number - 1] = '';
        else this.data[number] = '';
        this.onInputChange();
        this.setFocus(number - 1);
      });
    }
  }

  onInputChange() {
    this.pinCode = this.data.join('');
  }

  setFocus(number: number) {
    if (this.data[number])
      this.digits._results[number].nativeElement.setSelectionRange(0, this.data[number].length);
  }

  onPaste(event: any) {
    event.preventDefault();
    let paste = (event.clipboardData).getData("text");
    let regex = /^[a-zA-Z0-9]+$/;

    if (regex.test(paste)) {
      let pasteArray = paste.split('');
      for (let i = 0; i < this.length; i++) {
        if (pasteArray[i]) this.data[i] = pasteArray[i];
      }
      this.onInputChange();
      this.digits._results[Math.min(paste.length, this.length - 1)].nativeElement.focus();
    }
  }
}