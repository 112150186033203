import { Injectable, OnDestroy } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})

export class RefreshTokenService implements OnDestroy {
  private apiCallSubscription?: Subscription;
  refreshTokenIntervalLocalStorageKey :number = 0; //3600000;
  constructor(private authenticationService: AuthenticationService, private router: Router) {}

  startApiCalls(): void {
    // get expiration date of token using JwtHelperService
    let token = JSON.parse(localStorage.getItem('currentUser')!).authenticated.access_token;
    let tokenExpirationDate = new JwtHelperService().getTokenExpirationDate(token);

    let timeIntervalToNextRefreshToken = (tokenExpirationDate!.getTime() - new Date().getTime());
    
    // timeIntervalToNextRefreshToken in seconds
    if(timeIntervalToNextRefreshToken > 0)
    {
      this.refreshTokenIntervalLocalStorageKey = timeIntervalToNextRefreshToken;
      const interval$ = interval(this.refreshTokenIntervalLocalStorageKey);
      let _currentUser = JSON.parse(localStorage.getItem('currentUser')!);

      this.apiCallSubscription = interval$.pipe(switchMap(() =>
        this.authenticationService.refreshToken(_currentUser.authenticated.refresh_token))).subscribe (refreshTokenResponse =>
        {
          this.refreshLocalStorage(refreshTokenResponse);
        this.setApiInterval();
        },
        error =>
        {
          this.stopApiCalls();
          localStorage.clear();
          this.router.navigate(['/login']);
        }
        );
    }
else
{
  this.stopApiCalls();
  this.authenticationService.logout();
}

}

  stopApiCalls(): void {
    if (this.apiCallSubscription) {
      this.apiCallSubscription.unsubscribe();
    }
  }

  setApiInterval(): void {
    localStorage.setItem('refreshTokenInterval', this.refreshTokenIntervalLocalStorageKey.toString());
    this.stopApiCalls();
    this.startApiCalls();
  }

  ngOnDestroy(): void {
    this.stopApiCalls();
  }

  refreshLocalStorage(refreshTokenRepsonse: any) {
    const currentUser: any = JSON.parse(localStorage.getItem('currentUser')!);
   
    let currentUserLocalStoage = currentUser;
    if(refreshTokenRepsonse)
    {
      currentUserLocalStoage.authenticated.access_token =
      refreshTokenRepsonse.access_token;
    currentUserLocalStoage.authenticated.refresh_token =
      refreshTokenRepsonse.refresh_token;
    }


    localStorage.clear();
    
    localStorage.setItem('currentUser', JSON.stringify(currentUserLocalStoage));
  }
}
