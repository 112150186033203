import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ProfileSettingsComponent } from './profile-settings/profile-settings.component';
import { SharedModule } from '../../shared/components/shared.module';
import { ToastModule } from 'primeng/toast';
import { AcceptInviteComponent } from './accept-invite/accept-invite.component';
import { OrganizationTitle } from 'src/core/helpers/organization-helper';
const routes: Routes = [
  {
    path: '',
    component: ProfileSettingsComponent,
    title:'Profile settings  - ' + (sessionStorage.getItem('organisation') == 'no' ? OrganizationTitle.MusicNest: OrganizationTitle.MPO)
  },
];

@NgModule({
  declarations: [
    ProfileSettingsComponent,
    AcceptInviteComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    CommonModule,
    ToastModule
  ]
})
export class UserModule { }
