import { ResolveFn } from '@angular/router';
import { DistributionService } from '../services/distributions.service';
import { inject } from '@angular/core';
import { ReportsService } from 'src/app/reports/shared/services/reports.service';

export const distributionResolver: ResolveFn<any> = (route, state) => {
  const distributionId = route.params.id;
  return inject(DistributionService).getDistribution(distributionId);
}

export const reportsResolver: ResolveFn<any> = (route, state) => {
  return inject(ReportsService).getReporters();
}