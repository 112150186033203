import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { DistributionService } from '../shared/services/distributions.service';
import { ThemeService } from 'src/app/theme.service';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { FinanceService } from 'src/app/finance/shared/services/finance.service';

interface Distribution {
  id: string;
  status: string;
  transferDate: string;
  transferAmount: string;
  name: string;
  specificationNumber: string;
  actionLabel: string;
}

@Component({
  selector: 'app-distributions-list',
  templateUrl: './distributions-list.component.html',
  styleUrls: ['./distributions-list.component.scss'],
})
export class DistributionsListComponent implements OnInit {
  page: number = 0;
  currentPageIndex: number = 0;
  currentPageSize: number = 10;
  totalNumberOfData: number = 0;
  
  distributions: Distribution[] = [];
  constructor(
    private router: Router,
    private distributionService: DistributionService,
    private financeService: FinanceService,
    private route: ActivatedRoute,

  ) {}

  ngOnInit() {
    registerLocaleData(es);
    this.getDistributions(this.currentPageSize, this.currentPageIndex);
  }

  getDistributions(pageSize: number, pageIndex: number) {
    let organizationId = JSON.parse(localStorage.getItem('currentUser')!).board
      .organisationId;
    this.distributionService
      .getDistributions(
        organizationId,
        this.currentPageSize,
        this.currentPageIndex
      )
      .subscribe((response: any) => {
        this.distributions = [];
        response.forEach((x: any) => {
          this.distributions.push({
            id: x.id,
            status: x.isApproved ? (x.isPublished ? 'Paid' : 'Approved') : 'Drafted',
            transferDate: x.publishDate? x.publishDate: '-',
            transferAmount: x.totalAmountForTransfer,
            name: x.title,
            specificationNumber: x.distributionId,
            actionLabel: x.isSubmited ? 'Overview' : 'Continue',
          });
        });
        this.totalNumberOfData =
          this.distributions.length > 0 ? response[0]!.totalRecords : 0;
      });
  }

  addNewDistribution() {
    this.distributionService.setEditMode(false);
    this.distributionService.createDistribution().subscribe((res: any) => {
      this.router.navigate(['distributions/create', res.entityId]);
    });
    // navigate to distribution/create
  }
  distributionActionBtn(actionName: string, distribution: any) {
    console.log('Action name: ', actionName);
    this.distributionService.distroName =distribution.name;
    this.router.navigate(['distributions/control-center',distribution.id]);
  }

  editDistributionActionBtn(distribution: any) {
    console.log(distribution);
   this.distributionService.setEditMode(true);
    this.router.navigate(['distributions/create', distribution.id]);
  }

  items: MenuItem[] = [];
  showMenuToggle(report: any, event: Event, menu: any): void {
    // this.items = this.getMenuItems(report, report.status);
    // menu.toggle(event);
  }

  getMenuItems(currentRow: any, status: number) {
    return [
      { visible: false },
      {
        label: 'Edit Distribution details',
        icon: 'pi pi-pencil',
      },
    ];
  }

  onPageOptionsChange() {
    this.currentPageIndex = 0;
    this.page = 0;

    this.getDistributions(this.currentPageSize, this.currentPageIndex);
  }

  onPageChange(event: any) {
    this.currentPageIndex = event.first / event.rows;
    this.page = event.first;
    this.currentPageSize = event.rows;

    this.getDistributions(this.currentPageSize, this.currentPageIndex);
  }
  goToFinance(distributionId : string) {
    // get labels in this distribution
    this.financeService
      .getDistributionReportLabels(distributionId)
      .subscribe((response: any) => {
        if(response.length > 0){
          const url = this.router.serializeUrl(
            this.router.createUrlTree([
              'finance/overview',
              distributionId,
              response[0].boardId,0
            ])
          );
          console.log(url)
          window.open(url, '_blank');
        }        
        
      });
  }
}
