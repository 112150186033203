import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AuthenticationService } from 'src/core/services/authentication.service';
import { SpinnerService } from 'src/core/services/spinner.service';

@Component({
  selector: 'app-accept-invite',
  templateUrl: './accept-invite.component.html',
  styleUrls: ['./accept-invite.component.scss']
})

export class AcceptInviteComponent implements OnInit  {

  invitationId: string = '';
  boardId: string = '';
  currentUser: any;
  userId: string = '';
  constructor(
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private spinnerService:SpinnerService,
    private messageService:MessageService,
    private router: Router
  ) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser')!);
    this.invitationId = this.activatedRoute.snapshot.paramMap.get('invitationId') ?? '';
    this.boardId = this.activatedRoute.snapshot.paramMap.get('boardId') ?? '';
    this.userId = this.activatedRoute.snapshot.paramMap.get('userId') ?? '';

    this.acceptInvite();
  }

  acceptInvite() {
    this.spinnerService.show();
    if(this.currentUser == null)
    {
      this.spinnerService.hide();
      this.router.navigate(['/login']);
        this.messageService.add({
          severity: 'info',
          summary: 'Accept invitation',
          detail:
            'You are not logged in, Please log in first before accepting the invite',
        });
      
    }
    else
    {
      this.authenticationService
      .acceptInvite(this.boardId, this.invitationId)
      .subscribe((res: any) => {
   if(this.currentUser.user.id == this.userId)
    {
      this.authenticationService
      .getCurrentBoardPermissions(
        this.currentUser.authenticated.access_token
      )
      .subscribe((currentBoardsPermissions: any) => {
        this.currentUser.boardPermissions = currentBoardsPermissions;
        localStorage.setItem("currentUser", JSON.stringify(this.currentUser));
        this.spinnerService.hide();
        window.location.href = '/reports';
    });
    }
    else
    {
      this.spinnerService.hide();
      this.messageService.add({
        severity: 'info',
        summary: 'Accept invitation',
        detail:
          'You are not logged in as the user in the invitation',
      });
    }
    });
    }

  }
}
