import { Component } from '@angular/core';
import { SpinnerService } from '../../../core/services/spinner.service';
import { NgProgress } from 'ngx-progressbar';

@Component({
  selector: 'app-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
})

export class OverlayComponent {

  constructor(private progress: NgProgress, public spinnerService:SpinnerService) {
  }

  ngOnInit() {
    this.spinnerService.progressRef = this.progress.ref('myProgress');
  }
}
