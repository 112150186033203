import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpContext,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  interval
} from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { AppConfig } from '../../app/app.config';
import Jsona from 'jsona';
import { Router } from '@angular/router';
import { BYPASS_LOG } from '../Interceptors/auth.interceptor';
import { UUID } from 'angular2-uuid';
import { SpinnerService } from './spinner.service';
const dataFormatter = new Jsona();

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  updateMetafireDBUserEmail(id: any, Email: any, arg2: string) {
    throw new Error('Method not implemented.');
  }
  protected apiServer = AppConfig.settings.apiServer;
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: any = null;
  profileImageResponse!: any;
  currentLoggedUser = JSON.parse(localStorage.getItem('currentUser')!);
  ProfileImage: Subject<any> = new Subject<any>();
  isProfileSettings: Subject<any> = new Subject<any>();
  public ProfileSubmitting = new BehaviorSubject<any>('');
  public loggedIn = new BehaviorSubject<boolean>(false);
  public loggedinSubscribtion: Subscription = new Subscription();

  get isLoggedIn(): Observable<boolean> {
    return this.loggedIn.asObservable();
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    private spinnerService:SpinnerService
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem('currentUser')!)
    );
    this.currentUser = this.currentUserSubject.asObservable();

    this.ProfileImage.subscribe((res: any) => {
      this.profileImageResponse = res;
    });
  }

  setLoggedIn(val: boolean) {
    this.loggedIn.next(val);
    if (!val) this.currentUserSubject.next(null);
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }
  imageUploadRequest(uploadedFile: any) {
    const bodyContent = new FormData();
    bodyContent.append('file', uploadedFile.files[0]);

    return this.http.post(
      this.apiServer.metafireSuiteAPI + `/api/upload/file`,
      bodyContent
    );
  }

  submitProfileForm(
    currentUser: any,
    Form: any,
    ImageUrl: string,
    IsNewImageUploaded: boolean = false
  ) {
    let body: any = {
      data: {
        id: currentUser.user.id,
        type: 'users',
        attributes: {
          firstName: Form.value.FirstName,
          lastName: Form.value.LastName,
          email: Form.value.Email,
          hasImage: Form.value.hasImage,
          userType: 'BoardUser',
          isActive: true,
          createdDate: currentUser.createdDate,
          phoneNumber: Form.value.PhoneNumber,
          position: Form.value.JobTitle,
        },
        relationships: {
          organisations: {
            data: [
              {
                id: currentUser.board.organisationId,
                type: 'organisations',
              },
            ],
          },
        },
      },
      meta: {
        transactionId: UUID.UUID(),
      },
    };

    if (IsNewImageUploaded) {
      body.data.attributes.image = ImageUrl;
    }

    return this.http.patch(
      this.apiServer.authAPI + `/users/` + currentUser.user.id,
      body,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + currentUser.authenticated.access_token, // keep it as it is
          'Content-Type': 'application/vnd.api+json',
        }),
      }
    );
  }

  login(username: string, password: string) {
    let httpParams = new HttpParams()
      .append('grant_type', 'password')
      .append('username', username)
      .append('password', password)
      .append('client_id', 'metafire.js')
      .append('client_secret', 'v9DRw3CqfhDPS8sHLtWr5V79')
      .append('scope', 'metafire.suite offline_access');

    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Vary: 'Accept-Encoding',
    });
    let options = {
      headers: headers,
      context: new HttpContext().set(BYPASS_LOG, false),
    };

    return this.http.post(
      this.apiServer.authAPI + `/identity/connect/token`,
      httpParams,
      options
    );
  }

  refreshToken(currentToken: string) {
    let httpParams = new HttpParams()
      .append('grant_type', 'refresh_token')
      .append('refresh_token', currentToken)
      .append('client_id', 'metafire.js')
      .append('client_secret', 'v9DRw3CqfhDPS8sHLtWr5V79')
      .append('scope', 'metafire.suite offline_access');

    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Vary: 'Accept-Encoding',
    });
    let options = {
      headers: headers,
      context: new HttpContext().set(BYPASS_LOG, false), // to skip interciptor
    };

    return this.http.post(
      this.apiServer.authAPI + `/identity/connect/token`,
      httpParams,
      options
    );
  }

  timerTORefreshToken(): Observable<any> {
    // get current token
    const currentToken = this.currentLoggedUser?.authenticated.refresh_token;
    let intervalTime = 1 * 1000;
    return interval(intervalTime).pipe(
      // 15 minutes in milliseconds
      startWith(0),
      switchMap(() => this.refreshToken(currentToken))
    );
  }

  getCurrentUserDetails(token: string) {
    let headers = new HttpHeaders({
      Authorization: 'Bearer ' + token, // keep it as it is
    });
    let options = { headers: headers };

    return this.http
      .get(this.apiServer.authAPI + `/users/current`, options)
      .pipe(
        map((response: any) =>
          dataFormatter.deserialize(response, {
            preferNestedDataFromData: true,
          })
        )
      );
  }

  logout() {
    let currentLoggedUser = JSON.parse(localStorage.getItem('currentUser')!);
    if(currentLoggedUser != null)
      {
        let httpParams = new HttpParams()
        .append('grant_type', 'access_token')
        .append('token', currentLoggedUser.authenticated.access_token)
        .append('client_id', 'metafire.js')
        .append('client_secret', 'v9DRw3CqfhDPS8sHLtWr5V79')
        .append('scope', 'metafire.suite offline_access');
  
      let headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      });
      let options = {
        headers: headers,
        context: new HttpContext().set(BYPASS_LOG, false),
      };
  
      this.http
        .post(
          this.apiServer.authAPI + `/identity/connect/revocation`,
          httpParams,
          options
        )
        .subscribe({
          next: (refreshTokenResponse: any) => {
            this.spinnerService.hide();
       // remove user from local storage to log user out
       localStorage.clear();
  
       this.router.navigate(['/login']);
       this.loggedIn.next(false);
       this.currentUserSubject.next(null);
          },
          error: (error) => {
            this.spinnerService.hide();
            localStorage.clear();
  
            this.router.navigate(['/login']);
            this.loggedIn.next(false);
            this.currentUserSubject.next(null);
          }
         
        });
      }
      else
      {
        this.spinnerService.hide();
        localStorage.clear();
        this.router.navigate(['/login']);
       this.loggedIn.next(false);
       this.currentUserSubject.next(null);
      }
  
  }

  getCurrentBoardPermissions(token: string) {
    let headers = new HttpHeaders({
      Authorization: 'Bearer ' + token,
    });
    let options = { headers: headers };

    return this.http
      .get(
        this.apiServer.authAPI +
        `/boards?filter[productType]=MetafireSuite&filter.excludeRootBoard=false&page%5Bnumber%5D=0&page%5Bsize%5D=3&rootFirst=true&sort=-VisitDate`,
        options
      )
      .pipe(
        map((response: any) =>
          dataFormatter.deserialize(response, {
            preferNestedDataFromData: true,
          })

        )
      );
  }

  getCurrentBoard(boardId: string, token: string) {
    let headers = new HttpHeaders({
      Authorization: 'Bearer ' + token,
    });
    let options = { headers: headers };

    return this.http
      .get(this.apiServer.authAPI + `/boards/` + boardId, options)
      .pipe(
        map((response: any) =>
          dataFormatter.deserialize(response, {
            preferNestedDataFromData: true,
          })
        )
      );
  }

  resetPasswordByEmail() {
    return this.http.post(
      this.apiServer.authAPI + `/forgotpassword`,
      {
        data: {
          type: 'string',
          attributes: {
            email: this.currentLoggedUser.user.email,
            product: 'MetafireSuite',
          },
        },
      },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/vnd.api+json',
        }),
      }
    );
  }

  forgotPassword(email: string) {
    return this.http.post(
      this.apiServer.authAPI + `/forgotpassword`,
      {
        data: {
          type: 'string',
          attributes: {
            email: email,
            product: 'MetafireSuite',
          },
        },
      },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/vnd.api+json',
        }),
      }
    );
  }

  resetPassword(
    userId: string,
    newPassword: string,
    newRepeatedPassword: string
  ) {
    return this.http.post(
      this.apiServer.authAPI + `/forgotpassword/` + userId,
      {
        password: newPassword,
        confirmPassword: newRepeatedPassword,
      },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/vnd.api+json',
        }),
      }
    );
  }

  getUserBoardsRole(currentUser: any) {
    let headers = new HttpHeaders({
      Authorization: 'Bearer ' + currentUser.authenticated.access_token, // keep it as it is
    });
    let options = { headers: headers };
    return this.http
      .get(
        this.apiServer.authAPI + `/users/` + currentUser.user.id + `/boards`,
        options
      )
      .pipe(
        map((response: any) =>
          dataFormatter.deserialize(response, {
            preferNestedDataFromData: true,
          })
        )
      );
  }

  getRoles() {
    let headers = new HttpHeaders({
      Authorization:
        'Bearer ' + this.currentLoggedUser.authenticated.access_token, // keep it as it is
    });
    let options = { headers: headers };
    return this.http.get(this.apiServer.authAPI + `/roles`, options).pipe(
      map((response: any) =>
        dataFormatter.deserialize(response, {
          preferNestedDataFromData: true,
        })
      )
    );
  }

  createUser(userData: any) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/vnd.api+json' });
    let bodyContent = { data: { type: 'users', attributes: userData } }
    return this.http.post(this.apiServer.authAPI + `/users`, bodyContent, { headers: headers });
  }

  addUserToBoard(boardId: string, userId: string, email: string, fullName: string, role: string, accessToken: string) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/vnd.api+json',
      'Authorization': 'Bearer ' + accessToken,
    });

    let options = {
      headers: headers,
      context: new HttpContext().set(BYPASS_LOG, false),
    };
    let bodyContent = { type: "user", id: userId, email, fullName, role }
    const url = this.apiServer.metafireSuiteAPI + `/api/board-users?boardId=${boardId}`;
    return this.http.post(url, bodyContent, options);
  }


  sendOTP(email: string, organisation: string = 'dk') {
    let url = this.apiServer.newAuthAPI + '/api/login/sendotp';
    let body = {
      "email": email,
      "organization": organisation
    };

    return this.http.post(url, body);

  }

  validateOTP(email: string, organisation: string = 'dk', otp: string) {
    let url = this.apiServer.newAuthAPI + '/api/login/validateotp';
    let body = {
      "email": email,
      "organization": organisation,
      "otp": otp
    };

    return this.http.post(url, body);

  }

  acceptInvite(boardId: any, invitationId: any){
    let payload: any = {
      data:{
        id: invitationId,        
        type: "Authentication",
        attributes: {
          isAccepted:true
        }
      }
 
    }
    let url = this.apiServer.authAPI + `/invitations/${invitationId}?boardId=${boardId}`;
    let headers = new HttpHeaders({
      Authorization:
        'Bearer ' + this.currentLoggedUser.authenticated.access_token,
        'Content-Type': 'application/vnd.api+json', // keep it as it is
    });
    let options = { headers: headers };
    return this.http.patch(url, payload, options);

  }
  updateMetafireDUserEmail(userId: string, email: string, fullName: string, boardId: string, receiveNewReports?:boolean|null){

    let url = this.apiServer.metafireSuiteAPI + `/api/users/${userId}?boardId=${boardId}`;
    let body : any={
        Id: userId,
        Email:email,
        FullName: fullName,
        ReceiveNewReports: receiveNewReports
    };
    return this.http.patch(url, body)
  }

  getActiveAcceptanceService(organisation: string){
    let options = {
      context: new HttpContext().set(BYPASS_LOG, false),
    };
    let url = this.apiServer.newAuthAPI + `/api/AcceptanceTerms/active-acceptance-terms?OrganizationId=${organisation}`;
    return this.http.get(url,options);
  }

  acceptUserTerms(userId: string, activeAcceptanceTermsId: number){
    let options = {
      context: new HttpContext().set(BYPASS_LOG, false),
    };

    let body = {
      "userId": userId,
      "acceptanceTermId": activeAcceptanceTermsId,
    }
    let url = this.apiServer.newAuthAPI + `/api/AcceptanceTerms/UserAcceptanceTerms`;
    return this.http.post(url,body,options);
  }


  getActiveCookieTermsService(organisation: string){
    let options = {
      context: new HttpContext().set(BYPASS_LOG, false),
    };
    let url = this.apiServer.newAuthAPI + `/api/CookieTerms/GetActiveCookieTerms?OrganizationId=${organisation}`;
    return this.http.get(url,options);
  }

  acceptUserCookie(userId: string, cookieTermsId: number, isAccepted: boolean){
    let options = {
      context: new HttpContext().set(BYPASS_LOG, false),
    };

    let body = {
      "userId": userId,
      "cookieId": cookieTermsId,
      "isAccepted":isAccepted
    }
    let url = this.apiServer.newAuthAPI + `/api/CookieTerms/UpdateUserAcceptanceCookie`;
    return this.http.post(url,body,options);
  }

}
