import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MenuModule } from 'primeng/menu';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ChipsComponent } from './primeNg-components/chips/chips.component';
import { ChipsModule } from 'primeng/chips';
import { FormsModule } from '@angular/forms';
import { MultiSelectComponent } from './primeNg-components/multi-select/multi-select.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { DatepickerComponent } from './primeNg-components/datepicker/datepicker.component';
import { DateTimePickerComponent } from './primeNg-components/date-time-picker/date-time-picker.component';
import { ButtonModule } from 'primeng/button';
import { TabMenuModule } from 'primeng/tabmenu';
import { ButtonComponent } from './primeNg-components/button/button.component';
import { InputTextComponent } from './primeNg-components/input-text/input-text.component';
import { InputTextModule } from 'primeng/inputtext';
import { DropDownComponent } from './primeNg-components/drop-down/drop-down.component';
import { RadioButtonComponent } from './primeNg-components/radio-button/radio-button.component';
import { CheckBoxComponent } from './primeNg-components/check-box/check-box.component';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { PasswordComponent } from './primeNg-components/password/password.component';
import { PasswordModule } from 'primeng/password';
import { DialogModule } from 'primeng/dialog';
import { StepsModule } from 'primeng/steps';
import { SidebarModule } from 'primeng/sidebar';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { CascadeSelectComponent } from './primeNg-components/cascade-select/cascade-select.component';
import { FileUploadModule } from 'primeng/fileupload';
import { MenubarModule } from 'primeng/menubar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { OverlayComponent } from './overlay/overlay.component';
import { NgProgressModule } from 'ngx-progressbar';
import { PinCodeComponent } from './pin-code/pin-code.component';
import { TagModule } from 'primeng/tag';
import { TabViewModule } from 'primeng/tabview';
import { DividerModule } from 'primeng/divider';

@NgModule({
  declarations: [
    ChipsComponent,
    MultiSelectComponent,
    DatepickerComponent,
    DateTimePickerComponent,
    ButtonComponent,
    InputTextComponent,
    DropDownComponent,
    RadioButtonComponent,
    CheckBoxComponent,
    PasswordComponent,
    CascadeSelectComponent,
    OverlayComponent,
    PinCodeComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MenuModule,
    MenubarModule,
    PanelMenuModule,
    ChipsModule,
    MultiSelectModule,
    FileUploadModule,
    CalendarModule,
    TableModule,
    ButtonModule,
    InputTextModule,
    CheckboxModule,
    DropdownModule,
    RadioButtonModule,
    PasswordModule,
    ReactiveFormsModule,
    DialogModule,
    StepsModule,
    CascadeSelectModule,
    SidebarModule,
    TabMenuModule,
    ProgressSpinnerModule,
    NgProgressModule,
    TagModule,
    TabViewModule,
    DividerModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    ChipsComponent,
    MultiSelectComponent,
    DatepickerComponent,
    DateTimePickerComponent,
    FileUploadModule,
    ButtonComponent,
    InputTextComponent,
    DropDownComponent,
    RadioButtonComponent,
    CheckBoxComponent,
    DropdownModule,
    PasswordComponent,
    DialogModule,
    ButtonModule,
    StepsModule,
    CalendarModule,
    DropDownComponent,
    CascadeSelectComponent,
    SidebarModule,
    TabMenuModule,
    MenubarModule,
    OverlayComponent,
    PinCodeComponent,
    TagModule,
    TabViewModule,
    DividerModule
  ],
  providers: [
  ],
})
export class SharedModule { }

