<div>
  <div style="margin-bottom: 10px;">{{label}}</div>
  <div class="p-input-icon-left">
    <i style="position: relative; margin-right: -20px;" *ngIf="showIcon" [class]="icon"></i>
    <input type="text" pInputText [placeholder]="placeholder" [(ngModel)]="value" [minlength]="minlength" [maxlength]="maxlength" />
    <div  style="color: red; margin-top: 15px;" *ngIf="showLength && value && (value.length < minlength || value.length > maxlength)" class="p-invalid">Input must be between {{minlength}} and {{maxlength}} characters long.</div>
  </div>
</div>

<!-- <app-input-text
   [label]="''"
  [showIcon]="true"
   icon="pi pi-search"
   [showLength]="true"
   [label]="''" [placeholder]="''"
   [(ngModel)]="value" [minlength]="3" [maxlength]="10"></app-input-text>

-->
