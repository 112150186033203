import { SpinnerService } from 'src/core/services/spinner.service';
import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import {
  ActivatedRoute,
  ResolveEnd,
  ResolveStart,
  Router,
} from '@angular/router';
import { Reporter } from 'src/app/reports/shared/models/reporter.model';
import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import * as XLSX from 'xlsx';
import { FinanceService } from '../shared/services/finance.service';
import { UserRole } from 'src/core/helpers/user-roles';
import { Location } from '@angular/common';

@Component({
  selector: 'app-finance-overview',
  templateUrl: './finance-overview.component.html',
  styleUrls: ['./finance-overview.component.scss'],
})
export class FinanceOverviewComponent implements OnInit {
  LabelName: string = '';
  currentLabelIndex = 0;
  page: number = 0;
  currentPageIndex: number = 1;
  currentPageSize: number = 50;
  totalNumberOfData: number = 0;
  usages: string[] = [];
  royaltyStatement: any;
  vatStatement: any;
  tracks: any[] = [];
  tracksToExport: any[] = [];
  distributionReportLabels: any[] = [];
  distributions: any[] = [];
  financeTitle: any;
  financeDetails: any;
  distributionId: any;
  receivedFunds: any;
  reservedCosts: any;
  recievedFundsExcludingVAT: any;
  LabelSharePercentage: any;
  labelShareValue: any;
  labelVATAmountValue: any = 0;
  labelShareIncVATValue: any = 0;
  labelShareValueIncludingVAT: any;
  reservedCostsType: any;
  administrationCost: any;
  allLabelAdministrationCostVATAmountValue: any = 0;
  labelAdministrationCostIncVATValue: any = 0;
  currentYear: any;
  totalCostOfAdministrationInCurrentYear: any;
  totalCostOfAdministrationToOffset: any;
  LabelAdministratorFeesExcludingVAT: any = 0;
  LabelAdministratorFeesVATAmount: any = 0;
  LabelAdministratorFeesIncludingVAT: any = 0;
  labelOnAccount: any;
  labelPreviousBalance: any;
  distributionKey!: any[];
  organizationPrefix: string = 'dk';
  totalValue = 0;
  totalShare = 0;
  isSuperAdmin = false;
  items!: MenuItem[];
  currency: string = 'DKK';
  incomeStreams: any;
  incomeStreamsTotal = 0;
  labelRemuneratedAmount: number = 0;
  collectingLabel: string = '';
  isCollectingToItself: boolean = true;
  parentLabelTotalRemmurationAmount: number = 0;  
  showRemureationAlert: boolean = false;
  exportLoading: boolean = false;
  reporters: any[] = [];
  currentUser: any = {};
  searchBoardId: string = '';
  transferDate: any;
  RecievedFundVatAmountPrecent: any = 0;
  AdministrationFeesVatAmountPrecent: any = 0;
  showDetails: boolean = true;
  isReverseCharge: boolean = false;
  isDistributionPublished: boolean = false;
  isAdministrationFeesIncluded: boolean = false;
  isParentLabel: any = '0';
  constructor(
    private route: ActivatedRoute,
    private SpinnerService: SpinnerService,
    private router: Router,
    private financeService: FinanceService,
    private location: Location
  ) {
    // check if user is superadmin or not
    this.currentUser = JSON.parse(localStorage.getItem('currentUser')!);
    this.isSuperAdmin = [UserRole.Superadmin].includes(
      this.currentUser.user.roleId
    );
  }

  ngOnInit() {
    this.router.events.subscribe((ev) => {
      if (ev instanceof ResolveStart) this.SpinnerService.show();
      if (ev instanceof ResolveEnd) this.SpinnerService.hide();
    });

    registerLocaleData(es);
    this.getOrganizationPrefix();
    this.route.data.subscribe((response: any) => {
      this.reporters = response.reporters;
      this.distributionReportLabels = response.distributionReportLabels;

      let boardIdInURL = this.route.snapshot.params.boardId;
      this.isParentLabel = this.route.snapshot.params.isParentLabel;
      
        // check if the boardIdInURL is the same boardId in the localStorage
        if (this.currentUser.board.id == boardIdInURL || this.isSuperAdmin || this.isParentLabel == '1') {
          this.searchBoardId = boardIdInURL;

          if (this.isSuperAdmin || this.isParentLabel == '1') {
            // get the index of the selected Report Label
            this.currentLabelIndex = this.distributionReportLabels.findIndex(
              (item: any) => item.boardId == this.searchBoardId
            );
            this.selectedDistributionReportLabel =
              this.distributionReportLabels[this.currentLabelIndex];
            this.LabelName = this.selectedDistributionReportLabel.labelName;
          }
          this.getDistribution();
        } else {
          // replace boardId in the url with the one in the local storage
          this.searchBoardId = this.currentUser.board.id;

          // check if this.searchBoardId exist in the distributionReportLabels or not
          let isBoardExist = this.distributionReportLabels.some(
            (item: any) => item.boardId == this.searchBoardId
          );

          if (isBoardExist) {
            // update url with the correct boardId
            this.router.navigate([
              `/finance/overview/${this.route.snapshot.params.id}/${this.searchBoardId}`,
            ]);
            this.getDistribution();
          } else {
            // navigate to the reports
            this.router.navigate([`/reports`]);
          }
        }
      

      if (!this.isSuperAdmin) {
        this.LabelName = response.distribution[0].labelName;
      }

      this.selectedDistributionReportLabel =
        this.distributionReportLabels[this.currentLabelIndex];
      this.LabelName = this.isSuperAdmin
        ? this.selectedDistributionReportLabel.labelName
        : response.distribution[0].labelName;

      this.isCollectingToItself = response.distribution[0].isCollectingToItself;
      this.collectingLabel = response.distribution[0].collectingLabel;
      this.setFinance(response);
    });
  }

  // labelName && boardId
  getDistribution() {
    if (this.isSuperAdmin) {
      // change boardId in the URL
      const newUrl = `/finance/overview/${this.route.snapshot.params.id}/${this.selectedDistributionReportLabel.boardId}/0`;
      this.location.go(newUrl);
    }

    this.LabelName = (this.isSuperAdmin || this.isParentLabel== '1')
      ? this.selectedDistributionReportLabel.labelName
      : this.currentUser.board.title;
    this.searchBoardId = (this.isSuperAdmin || this.isParentLabel== '1')
      ? this.selectedDistributionReportLabel.boardId
      : this.currentUser.board.id;

    this.financeService
      .getLabelTracksFromDistributionReports(
        this.route.snapshot.params.id,
        this.searchBoardId
      )
      .subscribe((tracks: any) => {
        this.tracks = tracks.map((item: any) => {
          return {
            ...item,
            //finance: Number.parseFloat(item.finance).toFixed(2),
          };
        });
      });
    this.financeService
      .getDistributionDetails(this.route.snapshot.params.id, this.searchBoardId)
      .subscribe((distribution: any) => {
        console.log(
          'Is Admin Fees Included: ',
          this.isAdministrationFeesIncluded
        );
        this.financeService
          .getDistributionKey(this.route.snapshot.params.id, this.searchBoardId)
          .subscribe((distributionKey: any) => {
            // check if collectingLabel is the same as LabelName in all ditributionKey items
            // this.isCollectingToItself = distributionKey.every(
            //   (item: any) => item.collectingLabel == this.LabelName
            // );

            this.isCollectingToItself = distribution[0].isCollectingToItself;

            this.collectingLabel = distribution[0].collectingLabel;
            this.LabelName = distribution[0].labelName;

            this.setFinance({
              distribution,
              incomeStreams: this.incomeStreams,
              distributionKey,
            });
          });
      });
  }

  formatFinance(finance: string): string {
    const nordicFormatter = new Intl.NumberFormat('da-DK', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return nordicFormatter.format(Number.parseFloat(finance));
  }

  setFinance(response: any) {
    this.transferDate = response.distribution[0].publishDate;
    this.distributionKey = response.distributionKey.map((item: any) => {
      return {
        ...item,
        reporter: this.reporters.find(
          (reporter: Reporter) => reporter.Id === item.reporterId
        )!.Name,
      };
    });

    const { totalFinancialValue, totalValue } = this.distributionKey.reduce(
      (acc, item) => {
        acc.totalFinancialValue += item.totalFinancialValue;
        acc.totalValue += item.totalShareValue;

        return acc;
      },
      { totalFinancialValue: 0, totalValue: 0 }
    );

    this.totalValue = totalValue;
    this.totalShare = (totalValue / totalFinancialValue) * 100;

    this.setDistributionDetails(response.distribution);
    // this.tracks = response.distributionTracks;
    this.incomeStreams = response.incomeStreams;
    this.incomeStreams = this.incomeStreams.map((item: any) => {
      return {
        ...item,
        value: Number.parseFloat(item.value),
      };
    });
    this.incomeStreamsTotal = this.incomeStreams.reduce(
      (acc: number, item: any) => {
        return acc + item.value;
      },
      0
    );

    if (!this.searchBoardId)
      this.searchBoardId = this.route.snapshot.params.boardId;
    this.financeService
      .getLabelTracksFromDistributionReports(
        this.route.snapshot.params.id,
        this.searchBoardId
      )
      .subscribe((tracks: any) => {
        this.tracks = tracks.map((item: any) => {
          return {
            ...item,
            //finance: Number.parseFloat(item.finance).toFixed(2),
          };
        });
      });
  }

  selectedDistributionReportLabel!: any;
  // labelName && boardId
  setDistributionDetails([res]: any) {
    // add the logic for ReceivedFundVatAmountPrecent
    if (this.organizationPrefix == 'dk') {
      this.RecievedFundVatAmountPrecent =
        res.labelReceivedFundsVatAmount > 0 ? res.receivedFundsVatPercent : 0;
      this.AdministrationFeesVatAmountPrecent =
        res.labelAdministrationFeesVatAmount;
    } else if (this.organizationPrefix == 'no') {
      this.RecievedFundVatAmountPrecent =
        res.labelReceivedFundsVatAmount > 0 ? res.receivedFundsVatPercent : 0;
      this.AdministrationFeesVatAmountPrecent =
        res.labelAdministrationFeesVatAmount;
    }
    this.isAdministrationFeesIncluded = res.isAdministrationFeesIncluded;
    this.financeTitle = res.distributionTitle;
    this.financeDetails = res.distributionDescription;
    this.distributionId = res.distributionId;
    this.receivedFunds = res.fundsReceivedAmount;
    this.reservedCosts = res.reservationForUnknownRightsHoldersValue;
    this.reservedCostsType = res.reservationForUnknownRightsHoldersType;
    this.LabelSharePercentage = res.labelPercentageShare;
    this.labelShareValue =
      (this.LabelSharePercentage / 100) *
      (this.receivedFunds - res.reservationAmount);
    this.labelVATAmountValue = res.labelReceivedFundsVatAmount; //this.labelShareValue * (this.RecievedFundVatAmountPrecent/100);
    this.labelShareIncVATValue =
      this.labelShareValue + this.labelVATAmountValue;
    this.labelOnAccount = res.labelOnAccountValue;
    this.labelPreviousBalance = res.labelWithHoldingValue;
    this.usages =
      res.distributionUsages.trim().length > 0
        ? res.distributionUsages.split(',')
        : [];
    this.vatStatement = res.vatStatement;
    this.royaltyStatement = res.royaltyStatement;
    this.administrationCost = res.administrationFeesAmount;
    this.allLabelAdministrationCostVATAmountValue =
      this.administrationCost * (this.AdministrationFeesVatAmountPrecent / 100);
    this.labelAdministrationCostIncVATValue =
      this.administrationCost + this.allLabelAdministrationCostVATAmountValue;
    this.totalCostOfAdministrationInCurrentYear =
      res.totalCostOfAdministrationInCurrentYear;

    this.LabelAdministratorFeesExcludingVAT =
      (this.administrationCost * this.LabelSharePercentage) / 100;
    // old
      // this.LabelAdministratorFeesVATAmount =
    //   (this.allLabelAdministrationCostVATAmountValue *
    //     this.LabelSharePercentage) /
    //   100;
    this.LabelAdministratorFeesVATAmount = res.aggregatedLabelAdminVATAmount;
    this.LabelAdministratorFeesIncludingVAT =
      this.LabelAdministratorFeesExcludingVAT +
      this.LabelAdministratorFeesVATAmount;

    this.currentYear = res.distributionYear;

    this.totalCostOfAdministrationToOffset =
      res.totalCostOfAdministrationToOffset;

    if (this.reservedCostsType == 'percent')
      this.recievedFundsExcludingVAT =
        this.receivedFunds * (1 - this.reservedCosts / 100);
    else
      this.recievedFundsExcludingVAT = this.receivedFunds - this.reservedCosts;

    this.labelShareValueIncludingVAT =
      this.labelShareIncVATValue -
      this.LabelAdministratorFeesIncludingVAT -
      this.labelOnAccount;
    this.labelRemuneratedAmount =
      this.labelShareValueIncludingVAT + this.labelPreviousBalance;

    // clear isReverseCharge
    this.isReverseCharge = false;
    this.isReverseCharge = res.isReverseCharge;

    this.isDistributionPublished = res.isPublished;
    if(res.isCollectingToItself == false && this.labelRemuneratedAmount < 300)
    {
      console.log('Condition met: isCollectingToItself is false and labelRemuneratedAmount < 300');

      this.financeService
      .getDistributionDetails(this.route.snapshot.params.id, res.collectingLabelID).subscribe((res: any) => {
        console.log('getDistributionDetails response:', res);

      this.parentLabelTotalRemmurationAmount = this.getTotalParentLabelRemunerationAmount(res);
      });
      if(this.parentLabelTotalRemmurationAmount > 300)
      {
          this.showRemureationAlert = false;
      }
     
    }
    else if (res.isCollectingToItself == true && this.labelRemuneratedAmount < 300)
    {
      this.showRemureationAlert = true;
    }
else
{
  this.showRemureationAlert = false;
}
    this.items = [
      { label: 'Overview', routerLink: ['/finance'] },
      { label: `${this.financeTitle} (${this.distributionId})` },
    ];
  }

  getOrganizationPrefix() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser')!);
    const organizationId = currentUser.board.organisationId
      ? currentUser.board.organisationId
      : currentUser.board.organisation.id;

    switch (organizationId) {
      case '2195ed89-bd12-4e27-9bdf-d8fcd9b8f14d':
        this.organizationPrefix = 'dk';
        this.currency = 'DKK';
        break;
      case 'b8f77630-9666-4627-b234-f4a1b5329e17':
        this.organizationPrefix = 'no';
        this.currency = 'NOK';
        break;
    }
  }

  onPageOptionsChange() {
    // this.currentPageIndex = 1;
    // this.page = 0;
    // this.getFinanceTransactions(this.currentPageSize, this.currentPageIndex);
  }

  onPageChange(event: any) {
    this.currentPageIndex = event.first / event.rows + 1;
    this.page = event.first;
    this.currentPageSize = event.rows;

    //this.getFinanceTransactions(this.currentPageSize, this.currentPageIndex);
  }

  exportLabelTracks() {
    this.exportLoading = true;
    // change format of finance in tracks
    let _tracks = this.tracks.map((item: any) => {
      return {
        Artist: item.artistName,
        Release: item.albumName,
        Title: item.trackName,
        ISRC: item.isrc,
        Label: item.assignedTo,
        'Paid to': item.collectingLabel,
        Value: Number(item.finance),
      };
    });
    // Define the worksheet
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(_tracks);

    // Define the workbook
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Finance Data');

    // Export the workbook to Excel
    XLSX.writeFile(wb, `Trackdetails.xlsx`);

    this.exportLoading = false;
  }

  getPreviousLabelData() {
    this.currentLabelIndex = this.currentLabelIndex - 1;
    if (this.currentLabelIndex > 0)
      this.selectedDistributionReportLabel =
        this.distributionReportLabels[this.currentLabelIndex];
    else
      this.selectedDistributionReportLabel = this.distributionReportLabels[0];

    this.getDistribution();
  }

  getNextLabelData() {
    this.currentLabelIndex = this.currentLabelIndex + 1;
    if (this.currentLabelIndex < this.distributionReportLabels.length)
      this.selectedDistributionReportLabel =
        this.distributionReportLabels[this.currentLabelIndex];
    else
      this.selectedDistributionReportLabel =
        this.distributionReportLabels[this.distributionReportLabels.length - 1];

    this.getDistribution();
  }

  downloadInvoiceLoading: boolean = false;
  downloadInvoicePdf() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser')!);
    const organizationId = currentUser.board.organisationId
      ? currentUser.board.organisationId
      : currentUser.board.organisation.id;
    this.downloadInvoiceLoading = true;
    this.financeService
      .downloadInvoicePdf(
        organizationId,
        this.route.snapshot.params.id,
        this.searchBoardId
      )
      .subscribe(
        (blob: any) => {
          this.downloadInvoiceLoading = false;
          // Create a URL for the blob
          const url = window.URL.createObjectURL(blob);

          // Create a link element and trigger the download
          const a = document.createElement('a');
          a.href = url;
          a.download = `${this.LabelName}_${this.distributionId}_Invoice.pdf`;
          a.click();

          // Clean up the URL object
          window.URL.revokeObjectURL(url);
        },
        (err: any) => {
          //this.downloadInvoiceLoading = false;
          console.log(err);
        }
      );
  }

  transform(value: any): number {
    if (typeof value === 'string') {
      // Replace comma with dot for Danish format
      value = value.replace(',', '.');
    }
    return parseFloat(value);
  }
  getTotalParentLabelRemunerationAmount([res] : any) {
    let RecievedFundVatAmountPrecent;
    let AdministrationFeesVatAmountPrecent;
    if (this.organizationPrefix == 'dk') {
       RecievedFundVatAmountPrecent =
        res.labelReceivedFundsVatAmount > 0 ? res.receivedFundsVatPercent : 0;
       AdministrationFeesVatAmountPrecent =
        res.labelAdministrationFeesVatAmount;
    } else if (this.organizationPrefix == 'no') {
      RecievedFundVatAmountPrecent =
        res.labelReceivedFundsVatAmount > 0 ? res.receivedFundsVatPercent : 0;
       AdministrationFeesVatAmountPrecent =
        res.labelAdministrationFeesVatAmount;
    }
  
    let receivedFunds = res.fundsReceivedAmount;
    let reservedCosts = res.reservationForUnknownRightsHoldersValue;
    let reservedCostsType = res.reservationForUnknownRightsHoldersType;
    let LabelSharePercentage = res.labelPercentageShare;
    let labelShareValue =
      (LabelSharePercentage / 100) *
      (receivedFunds - res.reservationAmount);
    let labelVATAmountValue = res.labelReceivedFundsVatAmount; //let labelShareValue * (let RecievedFundVatAmountPrecent/100);
    let labelShareIncVATValue =
      labelShareValue + labelVATAmountValue;
    let labelOnAccount = res.labelOnAccountValue;
    let labelPreviousBalance = res.labelWithHoldingValue;
   
    
    let administrationCost = res.administrationFeesAmount;
    let allLabelAdministrationCostVATAmountValue =
      administrationCost * (AdministrationFeesVatAmountPrecent / 100);

    let LabelAdministratorFeesExcludingVAT =
      (administrationCost * LabelSharePercentage) / 100;
    let LabelAdministratorFeesVATAmount =
      (allLabelAdministrationCostVATAmountValue *
        LabelSharePercentage) /
      100;
    let LabelAdministratorFeesIncludingVAT =
      LabelAdministratorFeesExcludingVAT +
      LabelAdministratorFeesVATAmount;


      let recievedFundsExcludingVAT;
    if (reservedCostsType == 'percent')
       recievedFundsExcludingVAT =
        receivedFunds * (1 - this.reservedCosts / 100);
    else
      recievedFundsExcludingVAT = receivedFunds - reservedCosts;

    let labelShareValueIncludingVAT =
      labelShareIncVATValue -
      LabelAdministratorFeesIncludingVAT -
      labelOnAccount;
    let labelRemuneratedAmount =
      labelShareValueIncludingVAT + labelPreviousBalance;
    
    // clear isReverseCharge
    let isReverseCharge = false;
    isReverseCharge = res.isReverseCharge;
  console.log("Label Remuneration Amount: ", labelRemuneratedAmount);
    return labelRemuneratedAmount;
  }
}
