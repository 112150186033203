import { transition } from '@angular/animations';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { DistributionService } from 'src/app/distributions/shared/services/distributions.service';
import { FinanceService } from '../shared/services/finance.service';
import * as XLSX from 'xlsx';
interface FinanceModel {
  id: string;
  date: string;
  amount: number;
  name: string;
  distributionId: string;
  isParentLabel: boolean;
}

@Component({
  selector: 'app-finance-list',
  templateUrl: './finance-list.component.html',
  styleUrls: ['./finance-list.component.scss'],
})
export class FinanceListComponent implements OnInit {

  LabelName: string = '';

  page: number = 0;
  currentPageIndex: number = 0;
  currentPageSize: number = 10;
  totalNumberOfData: number = 0;
  expanded: boolean = false;
  expandedRows: { [key: string]: boolean } = {};
  transactions: FinanceModel[] = [];
  subLabelTransactions: any[] = [];
  exportLoading: boolean = false;
  tracks: any[] = [];
  constructor(
    private router: Router,
    private distributionService: DistributionService,
    private financeService: FinanceService
  ) {}

  ngOnInit() {
    registerLocaleData(es);

    // get Current Board Name from LocalStorage
    this.LabelName = JSON.parse(
      localStorage.getItem('currentUser')!
    ).board.title;

    this.getDistributions();
  }

  onPageOptionsChange() {
    this.currentPageIndex = 0;
    this.page = 0;

    this.getFinanceTransactions(this.currentPageSize, this.currentPageIndex);
  }

  onPageChange(event: any) {
    this.currentPageIndex = event.first / event.rows;
    this.page = event.first;
    this.currentPageSize = event.rows;

    // this.getFinanceTransactions(this.currentPageSize, this.currentPageIndex);
    this.getDistributions();
  }

  getDistributions() {
    let boardId = JSON.parse(localStorage.getItem('currentUser')!).board.id;
    this.financeService
      .getFinanceList(boardId, this.currentPageIndex, this.currentPageSize)
      .subscribe((response: any) => {
        this.totalNumberOfData = response[0].totalRecords;
        this.transactions = [];
        response.forEach((x: any) => {
          this.transactions.push({
            date: x.publishDate,
            amount: x.labelShareValueWithoutVAT,
            name: x.title,
            distributionId: x.distributionId,
            id: x.id,
            isParentLabel: x.isParentLabel,
          });
        });
      });
  }

  downloadTrackDetails(financeModel: FinanceModel) {
    // get the board Id
    let boardId = JSON.parse(localStorage.getItem('currentUser')!).board.id;

    this.financeService
      .getLabelTracksFromDistributionReports(financeModel.id, boardId)
      .subscribe((tracks: any) => {
        this.tracks = tracks;
        this.exportLabelTracks();
      });
  }

  exportLabelTracks() {
    this.exportLoading = true;
    // Define the worksheet
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.tracks);

    // Define the workbook
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Finance Data');

    // Export the workbook to Excel
    XLSX.writeFile(wb, this.LabelName + '- FinanceData.xlsx');

    this.exportLoading = false;
  }

  goToStatement(financeModel: FinanceModel) {
    // get current boardId
    let boardId = JSON.parse(localStorage.getItem('currentUser')!).board.id;

    this.router.navigate(['finance/overview', financeModel.id, boardId]);
  }

  goToStatementSubLabel(financeModel: any, boardId: string) {
    this.router.navigate(['finance/overview', financeModel.id, boardId]);
  }

  getFinanceTransactions(pageSize: number, pageIndex: number) {}

  toggleExpanded(id: string, record: any) {
    let boardId = JSON.parse(localStorage.getItem('currentUser')!).board.id;
    this.expandedRows[id] = !this.expandedRows[id];
    this.getSubLabelFinanceList(record.id, boardId);
  }

  isExpanded(id: string, record: any): boolean {
    return !!this.expandedRows[id];
  }

  getSubLabelFinanceList(distributionId: any, boardId: any) {
    this.financeService
      .getSubLabelFinanceList(distributionId, boardId)
      .subscribe((subLabelFinanceList: any) => {
        this.subLabelTransactions = subLabelFinanceList;
      });
  }

  getCurrentYearSuffix(record: any) {
    // record.createdAt is 2024-08-25T20:41:41.98 I need to extract the suffix of the year in this date 
    // and return it
    let date = new Date(record.createdAt);
    let year = date.getFullYear();
    let yearSuffix = year.toString().substr(2, 2);
    return yearSuffix;
  
  }
}
