<p-card>
    <p-toast [showTransformOptions]="'translateY(100%)'" [showTransitionOptions]="'600ms'"
        [showTransformOptions]="'translateX(100%)'"
        [hideTransitionOptions]="config.messageNotificationsDelay +'ms'"></p-toast>

    <p class="card-title">Forgot your password?</p>
    <p>We'll email you instructions on how to reset it.</p>

    <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()" class="p-fluid">
        <div class="mb-3">
            <span class="p-input-icon-left">
                <i class="fa fa-envelope"></i>
                <input pInputText id="email" type="text" placeholder="Email" formControlName="email" />
            </span>
            <p *ngIf="f.email.invalid && f.email.dirty" class="input-error">You need to input your email!</p>
        </div>
        <p-button type="submit" [loading]="loading" label="Reset Password" styleClass="p-button-success"
            class="w-100" />
    </form>
    <button pButton label="Oh wait, I remember my password." class="p-button-link mt-3" routerLink="/login"></button>
</p-card>