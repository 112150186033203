<div class="pageContainer">
  <p-toolbar styleClass="tablePageHeader">
    <ng-template pTemplate="left">
      <div>
        <h2><span class="screen-header">Finance</span></h2>
        <h6>
          <span class="screen-sub-header">{{ LabelName }}</span>
        </h6>
      </div>
    </ng-template>
  </p-toolbar>
  <div class="mpo-table mpo-table-reports">
    <p-table [value]="transactions">
      <ng-template pTemplate="header">
        <tr>
          <th>Transfer Date</th>
          <th></th>
          <th>Transfer Amount</th>
          <th>Name</th>
          <th>Distribution ID</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-transaction>
        <tr>
          <td>{{ transaction.date | CustomDate }}</td>
          <td><p-tag *ngIf="transaction.isParentLabel" value="Accumulated" [style]="{'background-color': '#33333394'}"/></td>
          <td>
            {{ transaction.amount | number : "" : "es" }}
          </td>
          <td>{{ transaction.name }}</td>
          <td>{{ transaction.distributionId }}</td>
          <td>
            <div class="table-action-col">
              <a class="btnassign" (click)="goToStatement(transaction)"
                >Go to statement</a>
              <!-- <a class="btnalias" (click)="downloadTrackDetails(transaction)"
                >Download trackdetails</a
              > -->
              <i *ngIf="transaction.isParentLabel" [ngClass]="isExpanded(transaction.id, transaction) ? 'pi pi-angle-up' : 'pi pi-angle-down'"
                (click)="toggleExpanded(transaction.id, transaction)"
              ></i>
            </div>
          </td>
        </tr>
        <tr *ngIf="isExpanded(transaction.id, transaction)">
          <td colspan="9">
            <p-table [value]="subLabelTransactions">
              <ng-template pTemplate="header">
                <tr>
                  <th>Label</th>
                  <th>Transfer Amount</th>
                  <th></th>
                </tr>
              </ng-template>
              T<ng-template pTemplate="body" let-subLabelTransaction>
                <tr>
                  <td>{{ subLabelTransaction.assignedTo }}</td>
                  <td>
                    {{ subLabelTransaction.labelShareValueWithoutVAT | number : "" : "es" }}
                  </td>
                  <td class="table-action-col">
                    <a class="btnassign" (click)="goToStatementSubLabel(subLabelTransaction, subLabelTransaction.boardId)"
                    >Go to statement</a>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="8">
            <p class="empty-records">No records found</p>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div class="mpo-paginator">
      <div>
        <span class="mx-1 text-color">Items per page: </span>
        <p-dropdown
          [(ngModel)]="currentPageSize"
          (ngModelChange)="page = 0"
          (onChange)="onPageOptionsChange()"
          [options]="[
            { label: 10, value: 10 },
            { label: 25, value: 25 },
            { label: 50, value: 50 }
          ]"
          optionLabel="label"
          optionValue="value"
        ></p-dropdown>
      </div>
      <p-paginator
        [first]="page"
        [rows]="currentPageSize"
        [totalRecords]="totalNumberOfData"
        (onPageChange)="onPageChange($event)"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{first} - {last} of {totalRecords}"
        [showPageLinks]="false"
        [showFirstLastIcon]="false"
      ></p-paginator>
    </div>
  </div>
</div>
