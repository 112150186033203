<div class="pageContainer">
  <p-toolbar styleClass="tablePageHeader">
    <ng-template pTemplate="left">
      <h3>{{ openDisputeTable ? totalItemsOfOpenedData : totalItemsOfSolvedData }} <span>Items</span></h3>
    </ng-template>

    <ng-template pTemplate="center">
      <div class="d-flex flex-wrap justify-content-center align-items-center">
        <span class="p-input-icon-right">
          <i class="pi pi-search search-image-inline" *ngIf="!searchQuery"></i>
          <i class="search-enter-image" *ngIf="searchQuery"><span (click)="search(searchQuery, true)">ENTER</span></i>
          <input type="text" placeholder='Type to search' pInputText [(ngModel)]="searchQuery"
            (keyup.enter)="search($event)" (ngModelChange)="onSearchChange(searchQuery)" />
        </span>
      </div>
    </ng-template>
  </p-toolbar>

  <p-tabView (onChange)="changeTable($event)" [activeIndex]="activeTab">

    <!-- Open Disputes tab -->
    <p-tabPanel>
      <ng-template pTemplate="header">
        <div class="report-tab tabdisputes">
          {{ totalItemsOfOpenedData | number }}
        </div>
        <div class="report-tab-text">
          <span>Open disputes</span>
        </div>
      </ng-template>
      <div class="mpo-table">
        <p-table [value]="filteredOpenDisputesData" [tableStyle]="{ 'min-width': '75rem' }" [customSort]="true"
          dataKey="Id" (sortFunction)="onSort($event)" rowExpandMode="single"
          (onRowExpand)="expandFirstRowDispute($event)">
          <ng-template pTemplate="header">
            <tr>
              <th [width]="'25%'">Release</th>
              <th [width]="'20%'">Artist</th>
              <th pSortableColumn="assignedTo" [width]="'20%'">
                Assigned to<p-sortIcon field="assignedTo"></p-sortIcon>
              </th>
              <th [width]="'20%'">Disputed by</th>
              <th pSortableColumn="disputeDate" [width]="'15%'">
                Date of dispute <p-sortIcon field="disputeDate"></p-sortIcon>
              </th>
              <th class="disabledHead" [width]="'10%'"></th>
              <th class="disabledHead" [width]="'5%'"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-data let-rowIndex="rowIndex" let-expanded="expanded">
            <tr>
              <td>
                <div class="container-img">
                  <img [src]='data.ReleaseCover || "assets/images/imagePlaceholder.png"' />
                  <p>{{ data.ReleaseName }}</p>
                </div>
              </td>
              <td>{{ data.ArtistNames }}</td>
              <td>{{ data.AssignedTo }}</td>
              <td>
                <span *ngIf="(data.Members.length > 1)" [pTooltip]="getTooltipContent(data.Members)"
                  tooltipPosition="top" style="border-bottom: 1px dotted #000;">Multiple</span>

                <span *ngIf="!(data.Members.length > 1)">{{data.Members[0].LabelName}}</span>
              </td>
              <td>{{ data.DisputeDate | date : "d. MMM. yyyy" }}</td>
              <td>
                <a class="btnassign" *ngIf="forMPOAdmin" (click)="showDialog(data)">Solve</a>
                <a class="btnreject" *ngIf="forLabelAdminAndUser" (click)="withdrawDispute(data)">Withdraw</a>
                <p-dialog [style]="{ 'width': '40rem', 'cursor': 'initial' }" (onHide)="onDialogHide()"
                  [(visible)]="visible" [modal]="true" [draggable]="false" [resizable]="false">
                  <ng-template pTemplate="header">
                    <div class="d-flex flex-column align-items-start">
                      <p class="fw-bold h2 mb-0">Solve dispute</p>
                      <p class="fw-light">
                        Assign
                        <span class="text-black fw-bold"> {{ selectedRow.ReleaseName }} </span>
                        to the rightful label.
                      </p>
                    </div>
                  </ng-template>
                  <p-table [value]="[selectedRow]">
                    <ng-template pTemplate="header">
            <tr>
              <th [width]="'50%'">Assigned to</th>
              <th [width]="'30%'">Date</th>
              <th class="disabledHead" [width]="'20%'"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-data>
            <tr class="hover-dialog" (click)="checkRadioButton = data">
              <td class="container-img">
                <img [src]="getImageURL(data)">
                <p>{{ data.AssignedTo }}</p>
              </td>
              <td>{{ data.DisputeDate | date : "d. MMM. yyyy" }}</td>
              <td>
                <p-radioButton value="{{ data.AssignedTo }}" [(ngModel)]="checkRadioButton"
                  inputId="checkRadioButton1"></p-radioButton>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <p-table [value]="[selectedRow]">
          <ng-template pTemplate="header">
            <tr>
              <th [width]="'50%'">Disputed By</th>
              <th [width]="'30%'">Date</th>
              <th class="disabledHead" [width]="'20%'"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-DisputeMembers>
            <tr class="hover-dialog" *ngFor="let data1 of DisputeMembers.Members" (click)="checkRadioButton = data1">
              <td class="container-img">
                <img [src]="getImageURL(data1)">
                <p>{{ data1.LabelName }}</p>
              </td>
              <td>{{ data1.InvolveDate | date : "d. MMM. yyyy" }}</td>
              <td>
                <p-radioButton value="{{ data1.LabelName }}" [(ngModel)]="checkRadioButton"
                  inputId="checkRadioButton2"></p-radioButton>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <ng-template pTemplate="footer">
          <div class="d-flex align-items-center">
            <p class="mb-0 me-2 text-start" *ngIf="checkRadioButton">
              Do you want to assign <span class="fw-bold">{{ selectedRow.ReleaseName }}</span>
              to <span class="fw-bold"> {{checkRadioButton.AssignedTo || checkRadioButton.LabelName}}</span>?
            </p>
            <button pButton [disabled]="!checkRadioButton" class="p-button-success ms-auto" label="Assign"
              (click)="assignDisputedLabel(data)"></button>
          </div>
        </ng-template>
        </p-dialog>
        </td>
        <td><i [ngClass]="expanded ? 'fa fa-caret-up' : 'fa fa-caret-down'" [pRowToggler]="data"></i></td>
        </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-data>
          <tr>
            <td colspan="7">
              <p *ngIf="currentFirstChildRowOfOpenDispute.length == 0" class="text-center my-3">
                This release doesn't appear in any open reports.
              </p>
              <p-table dataKey="Id" *ngIf="currentFirstChildRowOfOpenDispute.length"
                [value]="currentFirstChildRowOfOpenDispute">
                <ng-template pTemplate="header">
          <tr>
            <th [width]="'40%'">Report name</th>
            <th [width]="'30%'">Closing date</th>
            <th class="disabledHead" [width]="'15%'"></th>
            <th class="disabledHead" [width]="'15%'"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-currentFirstChildRowOfOpenDispute let-rowIndex="rowIndex"
          let-expanded="expanded">
          <tr>
            <td class="container-img">
              <img [src]="reportersIconsURL.replace('{{id}}',currentFirstChildRowOfOpenDispute.Reporter.Id)" />
              <div>
                <h6 style="font-weight: bold;">
                  {{ currentFirstChildRowOfOpenDispute.Reporter.Name }}
                  <i class="pi pi-circle-fill" style="font-size: 3px"></i>
                  {{ currentFirstChildRowOfOpenDispute.Quarter ? "" + currentFirstChildRowOfOpenDispute.Quarter :
                  "Annual"}}
                  <i class="pi pi-circle-fill" style="font-size: 3px"></i>
                  {{ currentFirstChildRowOfOpenDispute.Year }}
                </h6>
                <p>{{ currentFirstChildRowOfOpenDispute.SubTitle }}</p>
              </div>
            </td>
            <td>{{currentFirstChildRowOfOpenDispute.Closing | date : "d. MMM. yyyy"}}</td>
            <td><a class="btnalias" (click)="goToReport(currentFirstChildRowOfOpenDispute)">Go To</a></td>
            <td>
              <i [ngClass]="expanded ? 'fa fa-caret-up' : 'fa fa-caret-down'"
                [pRowToggler]="currentFirstChildRowOfOpenDispute"
                (click)="expandReleasesChildRowDispute(currentFirstChildRowOfOpenDispute)"></i>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-currentFirstChildRowOfOpenDispute>
          <tr>
            <td colspan="7">
              <p-table [value]="currentReleasesChildRowOpenDispute">
                <ng-template pTemplate="header">
          <tr>
            <th [width]="'50%'">Track</th>
            <th [width]="'50%'">ISRC</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-currentReleasesChildRowOpenDispute let-rowIndex="rowIndex">
          <tr>
            <td>
              {{currentReleasesChildRowOpenDispute.TracksCount
              }}.&nbsp;{{currentReleasesChildRowOpenDispute?.Tracks?.[0]?.TrackName}}
            </td>
            <td>{{ currentReleasesChildRowOpenDispute?.Tracks?.[0]?.Isrc}}</td>
          </tr>
        </ng-template>
        </p-table>
        </td>
        </tr>
        </ng-template>
        </p-table>
        </td>
        </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="7">
              <p class="empty-records">
                No records found
              </p>
            </td>
          </tr>
        </ng-template>
        </p-table>

        <div class="mpo-paginator">
          <div>
            <span class="mx-1 text-color">Items per page: </span>
            <p-dropdown [(ngModel)]="dataTableConfig.paginationConfig.pageSize"
              (ngModelChange)="dataTableConfig.paginationConfig.pageIndex = 0" (onChange)="getDisputes()"
              optionValue="value" optionLabel="label"
              [options]="[{ label: 10, value: 10 }, { label: 25, value: 25 }, { label: 50, value: 50 }]"></p-dropdown>
          </div>
          <p-paginator [first]="dataTableConfig.paginationConfig.pageIndex"
            [rows]="dataTableConfig.paginationConfig.pageSize"
            [totalRecords]="openDisputeTable ? totalItemsOfOpenedData : totalItemsOfSolvedData" [showPageLinks]="false"
            [showFirstLastIcon]="false" (onPageChange)="onPageChange($event)" [showCurrentPageReport]="true"
            currentPageReportTemplate="{first} - {last} of {totalRecords}"></p-paginator>
        </div>
      </div>
    </p-tabPanel>

    <!-- Solved Disputes tab -->
    <p-tabPanel>
      <ng-template pTemplate="header">
        <div class="report-tab tabassigned">
          {{ totalItemsOfSolvedData | number }}
        </div>
        <div class="report-tab-text">
          <span>Solved disputes</span>
        </div>
      </ng-template>
      <div class="mpo-table">
        <p-table [value]="filteredSolvedDisputesData" [tableStyle]="{ 'min-width': '75rem' }" [customSort]="true"
          dataKey="Id" (sortFunction)="onSort($event)" rowExpandMode="single"
          (onRowExpand)="expandFirstRowDispute($event)">
          <ng-template pTemplate="header">
            <tr>
              <th [width]="'30%'">Release</th>
              <th [width]="'20%'">Artist</th>
              <th [width]="'20%'" *ngIf="forMPOAdmin">Assigned to</th>
              <th pSortableColumn="solvedDispute" [width]="'20%'">
                Solved date<p-sortIcon field="solvedDispute"></p-sortIcon>
              </th>
              <th *ngIf="forLabelAdminAndUser" [width]="'20%'">Status</th>
              <th class="disabledHead" [width]="'10%'"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-data let-rowIndex="rowIndex" let-expanded="expanded">
            <tr>
              <td>
                <div class="container-img">
                  <img [src]='data.ReleaseCover || "assets/images/imagePlaceholder.png"' />
                  <p>{{ data.ReleaseName }}</p>
                </div>
              </td>
              <td>{{ data.ArtistNames }}</td>
              <td *ngIf="forMPOAdmin">{{ data.AssignedTo }}</td>
              <td>{{ data.SolvedDate | date : "d. MMM. yyyy" }}</td>
              <td *ngIf="forLabelAdminAndUser">
                <p-tag *ngIf="data.IsWin" [value]="'WON'" severity="success"></p-tag>
                <p-tag *ngIf="!data.IsWin || data.IsWin == null" [value]="'WITHDRAWN'" severity="danger"></p-tag>
              </td>
              <td><i [ngClass]="expanded ? 'fa fa-caret-up' : 'fa fa-caret-down'" [pRowToggler]="data"></i></td>
            </tr>
          </ng-template>
          <ng-template pTemplate="rowexpansion" let-data>
            <tr>
              <td colspan="7">
                <p *ngIf="currentFirstChildRowOfOpenDispute.length == 0" class="text-center my-3">
                  This release doesn't appear in any open reports.
                </p>
                <p-table dataKey="Id" *ngIf="currentFirstChildRowOfOpenDispute.length"
                  [value]="currentFirstChildRowOfOpenDispute">
                  <ng-template pTemplate="header">
            <tr>
              <th [width]="'40%'">Report name</th>
              <th [width]="'30%'">Closing date</th>
              <th class="disabledHead" [width]="'15%'"></th>
              <th class="disabledHead" [width]="'15%'"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-currentFirstChildRowOfOpenDispute let-rowIndex="rowIndex"
            let-expanded="expanded">
            <tr>
              <td class="container-img">
                <img [src]="reportersIconsURL.replace('{{id}}',currentFirstChildRowOfOpenDispute.Reporter.Id)" />
                <div>
                  <h6>
                    {{ currentFirstChildRowOfOpenDispute.Reporter.Name }}
                    <i class="pi pi-circle-fill" style="font-size: 3px"></i>
                    {{ currentFirstChildRowOfOpenDispute.Quarter ? "" + currentFirstChildRowOfOpenDispute.Quarter :
                    "Annual"}}
                    <i class="pi pi-circle-fill" style="font-size: 3px"></i>
                    {{ currentFirstChildRowOfOpenDispute.Year }}
                  </h6>
                  <p>{{ currentFirstChildRowOfOpenDispute.SubTitle }}</p>
                </div>
              </td>
              <td>{{currentFirstChildRowOfOpenDispute.Closing | date : "d. MMM. yyyy"}}</td>
              <td><a class="btnalias" (click)="goToReport(currentFirstChildRowOfOpenDispute)">Go To</a></td>
              <td>
                <i [ngClass]="expanded ? 'fa fa-caret-up' : 'fa fa-caret-down'"
                  [pRowToggler]="currentFirstChildRowOfOpenDispute"
                  (click)="expandReleasesChildRowSolvedDispute(currentFirstChildRowOfOpenDispute, data)"></i>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="rowexpansion" let-currentFirstChildRowOfOpenDispute>
            <tr>
              <td colspan="7">
                <p-table [value]="currentReleasesChildRowOpenDispute">
                  <ng-template pTemplate="header">
            <tr>
              <th [width]="'50%'">Track</th>
              <th [width]="'50%'">ISRC</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-currentReleasesChildRowOpenDispute let-rowIndex="rowIndex">
            <tr>
              <td>
                {{currentReleasesChildRowOpenDispute.TracksCount
                }}.&nbsp;{{currentReleasesChildRowOpenDispute?.Tracks?.[0]?.TrackName}}
              </td>
              <td>{{ currentReleasesChildRowOpenDispute?.Tracks?.[0]?.Isrc}}</td>
            </tr>
          </ng-template>
        </p-table>
        </td>
        </tr>
        </ng-template>
        </p-table>
        </td>
        </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="7">
              <p class="empty-records">
                No records found
              </p>
            </td>
          </tr>
        </ng-template>
        </p-table>

        <div class="mpo-paginator">
          <div>
            <span class="mx-1 text-color">Items per page: </span>
            <p-dropdown [(ngModel)]="dataTableConfig.paginationConfig.pageSize"
              (ngModelChange)="dataTableConfig.paginationConfig.pageIndex = 0" (onChange)="getDisputes()"
              optionValue="value" optionLabel="label"
              [options]="[{ label: 10, value: 10 }, { label: 25, value: 25 }, { label: 50, value: 50 }]"></p-dropdown>
          </div>
          <p-paginator [first]="dataTableConfig.paginationConfig.pageIndex"
            [rows]="dataTableConfig.paginationConfig.pageSize"
            [totalRecords]="openDisputeTable ? totalItemsOfOpenedData : totalItemsOfSolvedData" [showPageLinks]="false"
            [showFirstLastIcon]="false" (onPageChange)="onPageChange($event)" [showCurrentPageReport]="true"
            currentPageReportTemplate="{first} - {last} of {totalRecords}"></p-paginator>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>
</div>