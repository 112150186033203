<div class="card flex justify-content-center">
        <div style="margin-bottom: 10px;">{{label}}</div>

  <p-multiSelect [options]="options"
                  [(ngModel)]="selectedOption"
                  defaultLabel="Select a option" optionLabel="name"
                  multiple="false"  display="chip"></p-multiSelect>
</div>


<!--        how to use the component in the html files

            <app-multi-select [label]="'cities'"></app-multi-select>


-->
