<div class="pageContainer">
  <p-toolbar styleClass="tablePageHeader">
    <ng-template pTemplate="left">
      <h2><span class="screen-header">Distributions</span></h2>
    </ng-template>
    <ng-template pTemplate="right">
      <div class="d-flex flex-wrap justify-content-center align-items-center">
        <p-button
          label="Add new distribution"
          styleClass="p-button-success"
          (click)="addNewDistribution()"
        ></p-button>
      </div>
    </ng-template>
  </p-toolbar>
  <div class="mpo-table mpo-table-reports">
    <p-table [value]="distributions">
      <ng-template pTemplate="header">
        <tr>
          <th>Status</th>
          <th>Transfer Date</th>
          <th>Transfer Amount</th>
          <th>Name</th>
          <th>Specification number</th>
          <th class="disabledhead"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-distribution>
        <tr>
          <td>{{ distribution.status }}</td>
          <td>
            {{
              distribution.transferDate !== "-"
                ? (distribution.transferDate | CustomDatetime)
                : "-"
            }}
          </td>
          <td class="text-end">
            {{ distribution.transferAmount | number : "" : "es" }}
          </td>
          <td>{{ distribution.name }}</td>
          <td>{{ distribution.specificationNumber }}</td>
          <td>
            <div class="table-action-col">
              <a
              class="btnalias"
              style="min-width: 5rem"
              *ngIf="distribution.status == 'Drafted'"
              (click)="editDistributionActionBtn(distribution)">
              Edit
              </a>
              <a
                class="btnalias"
                
                (click)="goToFinance(distribution.id)"
                *ngIf="distribution.status != 'Approved'"
                >{{ distribution.status == "Drafted" ? "Preview" : "View Statements" }}</a
              >
              <a
                class="btnalias"
                style="min-width: 5rem"
                *ngIf="distribution.status == 'Approved'"
                (click)="goToFinance(distribution.id)"
                >Preview Statements</a>
             
              <a
                class="btnalias"
                (click)="
                  distributionActionBtn(distribution.actionLabel, distribution)
                "
                >Control Center</a>
              
              <p-menu
                #menu
                [model]="items"
                [popup]="true"
                appendTo="body"
              ></p-menu>
              <!-- <i class="pi pi-ellipsis-v" (click)="showMenuToggle(distribution, $event, menu)"></i> -->
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="8">
            <p class="empty-records">No records found</p>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div class="mpo-paginator">
      <div>
        <span class="mx-1 text-color">Items per page: </span>
        <p-dropdown
          [(ngModel)]="currentPageSize"
          (ngModelChange)="page = 0"
          (onChange)="onPageOptionsChange()"
          [options]="[
            { label: 10, value: 10 },
            { label: 25, value: 25 },
            { label: 50, value: 50 }
          ]"
          optionLabel="label"
          optionValue="value"
        ></p-dropdown>
      </div>
      <p-paginator
        [first]="page"
        [rows]="currentPageSize"
        [totalRecords]="totalNumberOfData"
        (onPageChange)="onPageChange($event)"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{first} - {last} of {totalRecords}"
        [showPageLinks]="false"
        [showFirstLastIcon]="false"
      ></p-paginator>
    </div>
  </div>
</div>
