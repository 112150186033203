<div style="margin-bottom: 10px;">{{label}}</div>
<div class="card flex justify-content-center">
  <p-dropdown
    [options]="options"
    [(ngModel)]="selectedOption"
    [placeholder]="placeholder"
    [editable]="true"
    optionLabel="{{optionLabel}}"
    (ngModelChange)="onSelectChange()"
    [disabled]="isDisabled"
    >
  </p-dropdown>
</div>


<!--
<div class="card flex justify-content-center">
  <app-drop-down
   [label]="''"
  [placeholder]="'Options'"
    [options]="options"
    [(selectedOption)]="selectedOption"
  ></app-drop-down>
</div>
 -->
