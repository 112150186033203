<div
  class="d-flex flex-column justify-content-center mpologin align-items-center gap-5"
>
  <img
    src="assets/images/{{ organizationPrefix }}/logo/logo.svg"
    width="100px"
  />

  <div class="main d-flex">
    <div class="left">
      <img [src]="image['Image link']" />
      <span class="metadata">
        <p class="fw-bold mb-1">{{ image["Artist"] }}</p>
        <p>{{ image["Country"] }}</p>
      </span>
    </div>
    <div
      class="d-flex flex-column justify-content-between flex-fill p-5 bg-white border rounded-end-1"
    >
      <div class="d-flex flex-column">
        <h1
          class="fw-bold"
          *ngIf="
            organizationPrefix == 'dk' &&
            !showAcceptanceTermsModal &&
            !showAcceptanceCookieModal
          "
        >
          Access mit.MPO
        </h1>
        <h1
          class="fw-bold"
          *ngIf="
            organizationPrefix == 'no' &&
            !showAcceptanceTermsModal &&
            !showAcceptanceCookieModal
          "
        >
          Access myMusicNest
        </h1>
        <div
          *ngIf="
            !codeDelivered &&
            !showAcceptanceTermsModal &&
            !showAcceptanceCookieModal
          "
        >
          <p class="mb-0">
            Submit your email address to receive a secure, one-time code.
          </p>
          <p>This code will remain valid for 15 minutes.</p>
        </div>
        <div
          *ngIf="
            codeDelivered &&
            !showAcceptanceTermsModal &&
            !showAcceptanceCookieModal
          "
        >
          <p class="mb-0">
            We have sent an mail to <span class="fw-bold">{{ email }}</span
            >.
          </p>
          <p class="mb-0">
            Please check your spam folder if you don't receive an email.
          </p>
          <p>
            This code will remain valid for
            {{ otpValidityInSeconds / 60 }} minutes.
          </p>
        </div>
        <h2 class="fw-bold" *ngIf="showAcceptanceTermsModal">
          Accept Terms & Conditions
        </h2>
        <h2 class="fw-bold" *ngIf="showAcceptanceCookieModal">
          Accept the use of cookies
        </h2>
      </div>
      <div class="py-4"></div>
      <div
        class="d-flex flex-column gap-3"
        *ngIf="
          !codeDelivered &&
          !showAcceptanceTermsModal &&
          !showAcceptanceCookieModal
        "
      >
        <div class="d-flex flex-column w-100 gap-2">
          <label for="email" class="fw-bold small">E-mail</label>
          <input
            pInputText
            id="email"
            [(ngModel)]="email"
            placeholder="Enter your e-mail address..."
            (keydown.enter)="sendOTPCode()"
          />
        </div>

        <p-button
          label="Receive a one-time code via email"
          styleClass="p-button-success w-100"
          (onClick)="sendOTPCode()"
        ></p-button>
      </div>
      <div
        class="d-flex flex-column gap-1"
        *ngIf="
          codeDelivered &&
          !showAcceptanceTermsModal &&
          !showAcceptanceCookieModal
        "
      >
        <app-pin-code
          [(ngModel)]="pinCode"
          [length]="pinCodeLength"
          [wrongCode]="wrongCode"
          (ngModelChange)="validateOTP()"
        ></app-pin-code>
        <p-button
          label="Send new code"
          styleClass="p-button-success w-100"
          (onClick)="sendOTPCode()"
          *ngIf="counter == otpValidityInSeconds"
        ></p-button>
        <p-button
          label="Send new code in {{ counter }} seconds..."
          styleClass="p-button-secondary w-100"
          [disabled]="true"
          *ngIf="counter != otpValidityInSeconds"
        ></p-button>
        <button
          pButton
          label="go back"
          class="p-button-link mt-2 mx-auto"
          (click)="goBack()"
          style="width: fit-content !important"
          icon="pi pi-arrow-left"
        ></button>
      </div>

      <div class="p-col-8" *ngIf="showAcceptanceTermsModal">
        <div
          class="d-flex flex-column gap-3"
          style="width: 480px; height: 350px; overflow-y: auto; font-size: 14px"
          [innerHTML]="htmlContent"
        ></div>
        <p-button
          label="Approve"
          pAutoFocus
          [autofocus]="true"
          styleClass="p-button-success"
          icon="pi pi-check"
          (onClick)="submitAcceptanceTerms()"
        ></p-button>
      </div>

      <div *ngIf="showAcceptanceCookieModal">
        <div class="row" style="width:480px">
          <p>By approving, you help us improve our platform and support. We do not share your data with third parties.</p>
        </div>
        <div
          class="d-flex flex-column gap-3"
          style="
            width: 480px;
            height: 350px;
            overflow-y: auto;
            font-size: 14px;
            padding-bottom: 10rem;
          "
          [innerHTML]="cookieHtmlContent"
        ></div>
        <div class="row" style="margin-top: 2rem">
            <p-button
              styleClass="p-button-success"
              (click)="submitAcceptanceCookies()"
              label="Approve and confirm"
              pAutoFocus
              [autofocus]="true"
              [style]="{'width': '100%'}"
            ></p-button>
        </div>
        <div class="row" style="margin-top: 1rem">
          <p-button
            styleClass="p-button-link"
            (click)="declineAcceptanceCookies()"
            [style]="{'width': '100%', 'justify-content': 'center'}">
            Do not approve and continue</p-button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <p-dialog
  header="Acceptance of terms"
  [(visible)]="showAcceptanceTermsModal"
  [draggable]="false"
  [style]="{ width: '40vw', height: '500px', fontSize: '14px', fontWeight: '500'}"
  (onHide)="cancelAcceptanceTerms()"
>
<ng-template  pTemplate="body">
  <div [innerHTML]="htmlContent"></div>
</ng-template>
  
  <ng-template pTemplate="footer">
    <div class="custom-p-dialog-footer">
      <p-button
      icon="pi pi-check"
      styleClass="p-button-success"
      (click)="submitAcceptanceTerms()"
      label="Accept"
      pAutoFocus
      [autofocus]="true">
    </p-button>
    </div>
    
  </ng-template>
</p-dialog> -->
