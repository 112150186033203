import { Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss']
})
export class RadioButtonComponent implements OnInit {
  @Input() direction = '';
  @Input() selectedCategories: any[] = [];
  @Input()  formGroup!: FormGroup;
  @Input() categories: any[] = [];
  @Input() name = '';
  @Input() value = '';
  @Input() label!: string
  @Input() inputId = '';
  @Input() selectedValue = '';
  @Output() onChange = new EventEmitter<any>();

  ngOnInit() {
    this.formGroup = new FormGroup({
        selectedCategory: new FormControl()
    });
}
}
