import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss']
})
export class DateTimePickerComponent {
  @Input() label!: string;
  @Input() placeholder!: string;
  @Input() timeOnly?: boolean;
  @Input() showTime: boolean = true;

  date: Date[] | undefined;

}
