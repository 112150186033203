export interface PaginationConfig {
  pageIndex: number;
  pageSize: number;
}

export interface DataTableConfig {
  searchString: string;
  sortOrder: string;
  sortProperty: string;
  paginationConfig: PaginationConfig;
}

export const DefaultDataTableConfig: DataTableConfig = {
  searchString: '',
  sortOrder: '',
  sortProperty: '',
  paginationConfig: {
    pageIndex: 0,
    pageSize: 10,
  },
};

export interface PaginatedData {
  total: number;
}


export interface FilterModel {
  pageIndex: number;
  pageSize: number;
  searchQuery: string | null;
  sortOrder: string;
  sortProperty: string;
}

export interface ReportFilterModel extends FilterModel {
  statuses: number[];
}
