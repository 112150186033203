

  

  <div style="margin-bottom: 10px;">{{label}}</div>
  <p-password placeholder="Enter password" [feedback]="feedback" [(ngModel)]="value" [toggleMask]="true"></p-password>



<!-- 
  <app-password [label]="''"  [value]="value" [feedback]="true"></app-password>
 -->