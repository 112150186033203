import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-check-box',
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.scss'],
})
export class CheckBoxComponent implements OnInit {
  @Input() selectedCategories: any[] = [];
  @Input() categories: any[] = [];
  @Input() direction = '';
  @Input() label!: string;
  @Output() selectedOptionChange = new EventEmitter<string>()


  ngOnInit() {
  }
}
