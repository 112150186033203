<div class="">
  <div style="margin-bottom: 10px;">{{label}}</div>
  <p-dropdown (click)="sendDate($event)" [options]="options" [(ngModel)]="selectedCountry" optionLabel="name" [showClear]="true"
    placeholder="{{placeholder}}" [showClear]="false" value="value" >
    <ng-template pTemplate="selectedItem" style="width: 100px;">
      <div class="imm gap-2" *ngIf="selectedCountry">
          <img *ngIf="isimg"  src="{{ selectedCountry.imgsrc }}" alt="" style="width:25px;height:25px;margin-right: 5px;">
        <div>{{ selectedCountry.name }}</div>
      </div>
    </ng-template>
    <ng-template let-country pTemplate="item">
      <div class="imm gap-2">
        <img *ngIf="isimg" src="{{country.imgsrc}}" alt="" style="width:25px;height:25px;margin-right: 5px;">
        <div>{{ country.name }}</div>
      </div>
    </ng-template>
  </p-dropdown>
</div>
