export enum UserRole {
    Superadmin = 'a6d3e977-88ce-4c6b-bc8a-77ca12eb8e88',
    LabelAdmin = '7719eddc-e9ac-4927-b028-4dd81f58c5bc',
    LabelUser = '3ac6ce7f-5d69-4742-b480-009babb5a136',
    LabelAgent = '501ee438-c9f8-4850-87ff-04466531aa50'
  }

 export enum UserRoleNames {
  Superadmin = 'Superadmin',
  LabelAdmin = 'Label Admin',
  LabelUser = 'Label User',
  LabelAgent = 'Label Agent'
 } 