import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {

  private renderer!: Renderer2;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private rendererFactory: RendererFactory2,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  switchTheme(theme: string) {
    let themeLink = this.document.getElementById(
      'app-theme'
    ) as HTMLLinkElement;
    let favIconLink = this.document.getElementById(
      'fav-icon'
    ) as HTMLLinkElement;

    if (themeLink) {
      themeLink.href = theme + '-theme.css'; // bundle name
    }

    if(favIconLink)
    {
      favIconLink.href = window.location.origin+'/'+ theme + '-favicon.ico';
    }
  }
}
