import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-cascade-select',
  templateUrl: './cascade-select.component.html',
  styleUrls: ['./cascade-select.component.scss']
})
export class CascadeSelectComponent {


  @Output() dateEvent = new EventEmitter<any>();

  @Input() label!: string;
  @Input() placeholder!: string;

  @Input() selectedOption!: string;

  @Input() imgSrc!: any[];

  @Input() isimg!: boolean;

  @Input() value!: any;

  @Input() options: any[] | undefined;

  selectedCountry: any | undefined;

  constructor() { }
  ngOnInit(): void { }

  sendDate(event: any) {
    this.dateEvent.emit(event.target.ariaLabel);
  }

}
