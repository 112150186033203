import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/core/services/authentication.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
    resetPasswordForm!: FormGroup;
    loading = false;
    submitted = false;
    currentUserId: string = "";
    error = '';
    showPassword: boolean = false;
    showRepeatedPassword: boolean = false;
    constructor(private formBuilder: FormBuilder, private router: Router,
        private authenticationService: AuthenticationService,
        private activatedRoute: ActivatedRoute) { }

    ngOnInit(): void {
        //Clear all data in local storage
        localStorage.clear();
        this.authenticationService.loggedIn.next(false);

        // get User Id in url route
        this.activatedRoute.params.subscribe(params => {
            this.currentUserId = params['id'];
        });


        this.resetPasswordForm = this.formBuilder.group({
            newPassword: ['',
                [
                    Validators.required,
                    Validators.minLength(8),
                    Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,}$/)
                ]
            ],
            newRepeatedPassword: ['', [
                Validators.required,
                this.passwordMatchValidator()]
            ]
        });

    }

    // create password match validatro function
    // tslint:disable-next-line: typedef
    passwordMatchValidator() {
        return (control: FormControl) => {
            if (control.parent && control.parent.controls) {
                const newPassword = this.f.newPassword.value;
                const newRepeatedPassword = this.f.newRepeatedPassword.value;
                if (newPassword !== newRepeatedPassword) {
                    return { passwordMismatch: true };
                }
            }
            return null;
        };
    }

    // convenience getter for easy access to form fields
    get f() { return this.resetPasswordForm.controls; }
    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.resetPasswordForm.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService
            .resetPassword(this.currentUserId,
                this.f.newPassword.value,
                this.f.newRepeatedPassword.value)
            .subscribe((res1: any) => {
                this.authenticationService.logout();
            },
                error => {
                    this.error = error;
                    this.loading = false;
                });

    }
}
