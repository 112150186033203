<p-toast [showTransformOptions]="'translateY(100%)'" [showTransitionOptions]="'600ms'"
  [hideTransitionOptions]="config.messageNotificationsDelay +'ms'"
  [showTransformOptions]="'translateX(100%)'"></p-toast>

<div class="container">
  <div class="row">
    <div class="col-md-12 p-5">
      <form [formGroup]="ProfileForm">
        <h3 class="fw-bold">Profile</h3>
        <p>Manage your personal and contact details</p>
        <div class="row">
          <div class="col-md-3 d-flex flex-column align-items-center">
            <div class="col-12 profileImage d-flex flex-column align-items-center">
              <img class="rounded-circle" *ngIf="uploadedImage" [src]="uploadedImage" alt="Image"
                style="width: 170px;height: 170px;z-index:3000;">
              <div class="UploaderParent" *ngIf="!uploadedImage" style="cursor: pointer;" (click)="onCustomLinkClick()">
                <i class="pi pi-plus"></i>
                <p-fileUpload #fileUpload name="demo[]" (onSelect)="onSelectImage($event)" [multiple]="false"
                  accept="image/*" [maxFileSize]="50 * 1048576">
                </p-fileUpload>
              </div>
            </div>
            <div class="col-12 d-flex justify-content-center mt-3">
              <a *ngIf="uploadedImage" style="color: #00aae7;font-size:16px; cursor: pointer;"
                (click)="removeImage()">Remove Image</a>
              <a *ngIf="!uploadedImage" style="color:#00aae7;font-size:16px; cursor: pointer;text-decoration: none;"
                class="mt-2" (click)="onCustomLinkClick()">Upload image</a>
            </div>
          </div>
          <div class="col-md-9">
            <div class="row">
              <div class="col-md-6">
                <label htmlFor="FirstName" class="mb-3">First name</label>
                <input pInputText id="FirstName" placeholder="E.g.''Bruce''" (input)="onchange()"
                  formControlName="FirstName" />
              </div>
              <div class="col-md-6">
                <label htmlFor="LastName" class="mb-3">Last name</label>
                <input pInputText id="LastName" placeholder="E.g.''Wayne''" (input)="onchange()"
                  formControlName="LastName" />
              </div>
            </div>
            <div class="col-md-12 mt-3">
              <label htmlFor="Email" class="mb-3">E-mail address</label>
              <input pInputText id="Email" placeholder="E.g.''mail@domain.com''" type="email" (input)="onchange()"
                formControlName="Email" />
            </div>
            <div class="col-md-12 mt-3">
              <label htmlFor="Phone" class="mb-3">Phone</label>
              <input pInputText id="Phone" placeholder="E.g.''88888888''" type="number" (input)="onchange()"
                formControlName="PhoneNumber" />
            </div>
            <div class="col-md-12 mt-3">
              <label htmlFor="JobTitle" class="mb-3">Job title</label>
              <input pInputText id="JobTitle" placeholder="E.g.''Label manager''" (input)="onchange()"
                formControlName="JobTitle" />
            </div>
          </div>
        </div>
      </form>
      <div class="mt-3 d-flex justify-content-end align-items-end">
        <p-button label="Cancel" styleClass="p-button-danger me-2" (click)="routing()"></p-button>
        <p-button type="submit" label="Save changes" styleClass="p-button-success" (onClick)="submitForm()"
          [disabled]="isdisable" [loading]="loading" ></p-button>
      </div>
    </div>
  </div>
</div>