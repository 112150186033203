import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpContextToken,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

const helper = new JwtHelperService();

export const BYPASS_LOG = new HttpContextToken(() => true);
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // check if the user is logged in
    if (localStorage.getItem('currentUser')) {
      const authenticated: any = JSON.parse(
        localStorage.getItem('currentUser')!
      ).authenticated;
      // check if the token expireation date is less than the current date by 5 minutes
      if (authenticated && !helper.isTokenExpired(authenticated.access_token)) {
        const tokenExpirationDate = helper.getTokenExpirationDate(
          authenticated.access_token
        );
        const currentDate = new Date();
        if (currentDate > tokenExpirationDate!) {
          return next.handle(req);
        }
      } else {
        return next.handle(req);
      }
    }

    let skipedAPIs = ['sendOTP','validateOTP'];
    // how to check if the urls includes one of the skipedAPIs
    let isSkipped = skipedAPIs.some((api) => req.url.includes(api.toLowerCase()));
    
    // check if the request is to bypass the interceptor
    if (req.context.get(BYPASS_LOG) === true && req.url.includes('/api/') && !isSkipped) {
      const currentUser: any = JSON.parse(
        localStorage.getItem('currentUser')!
      );
      if (currentUser.authenticated.access_token) {
        var cloned = req.clone({
          setHeaders: {
            Authorization: `Bearer ${currentUser.authenticated.access_token}`,
            boardId:currentUser.board.id
          },
        });
       
        return next.handle(cloned); 
      } else {
        return next.handle(req);
      }
    }
    return next.handle(req);
  }
}
