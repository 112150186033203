import { Injectable } from '@angular/core';
import { FileUpload } from 'primeng/fileupload';
import { Subject } from 'rxjs';
import { Form03 } from '../models/form03';
import { Router } from '@angular/router';
import { AppConfig } from '../../../app.config';
import { Observable, throwError, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

import Jsona from 'jsona';
import { Reporter } from '../models/reporter.model';
import { PagedResponse } from 'src/shared/models/page-response.model';
import { ReportModel } from '../models/report.model';
const dataFormatter = new Jsona();
@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  dropdown!: any[];
  headers01!: HttpHeaders;
  headers02!: HttpHeaders;
  headers03!: HttpHeaders;
  protected apiServer = AppConfig.settings.apiServer;

  // ProfileImage: Subject<any> = new Subject<any>();
  response01: Subject<any> = new Subject<any>();
  response03: Subject<any> = new Subject<any>();

  close: Subject<any> = new Subject<any>();

  token: any = JSON.parse(localStorage.getItem('currentUser')!);
  accessToken = this.token.authenticated.access_token;

  form01!: any;
  reportLink!: URL;
  form03!: Form03[];

  constructor(private http: HttpClient) {}

  UploadFile(uploadedFile: FileUpload) {
    return this.http
      .post(
        this.apiServer.metafireSuiteAPI +
          `/api/upload?boardId=${this.token.board.id}`,
        uploadedFile,
        { headers: this.headers02 }
      )
      .subscribe((res: any) => {
        this.reportLink = res.ReportLink;
        this.dropdown = res.Headers;
      });
  }

  getReporters() {
    return this.http.get<Reporter[]>(
      this.apiServer.metafireSuiteAPI +
        `/api/reporters?boardId=${this.token.board.id}`
    );
  }

  SaveReport() {
    return this.http.post(
      this.apiServer.metafireSuiteAPI +
        `/api/reports?boardId=${this.token.board.id}`,
      {
        Mapping: {
          Isrc: this.form03[4].value,
          ArtistTitle: this.form03[2].value,
          TrackTitle: this.form03[0].value,
          AlbumTitle: this.form03[1].value,
          DurationTitle: this.form03[6].value,
          StreamsTitle: this.form03[8].value,
          DownloadsTitle: this.form03[7].value,
          LabelTitle: this.form03[5].value,
          Upc: this.form03[3].value,
        },
        SubTitle: this.form01.subtitle,
        Year: this.form01.year,
        Quarter: this.form01.period == 'Annual'? null: this.form01.period,
        Closing: this.form01.date,
        ReportFileUri: this.reportLink,
        Reporter: {
          Id: this.form01.reporter.Id,
        },
      }
    );
  }

  getReports(
    boardId: string,
    pageIndex: number,
    pageSize: number,
    sortedBy: string = 'name',
    searchQuery: string,
    selectedStatuses: string[],
    sortDirection: string,
    userId: string
  ): Observable<any> {
    let filter = `filterModel.pageIndex=${pageIndex}&filterModel.pageSize=${pageSize}&filterModel.sortOrder=${sortDirection}`;
    filter += `&filterModel.sortProperty=${sortedBy}&filterModel.searchQuery=${searchQuery}`;
    let statusParams = selectedStatuses
      .map((status) => `&filterModel.statuses=${status}`)
      .join('');
    var currentUser = JSON.parse(localStorage.getItem('currentUser')!);
    const organizationId = currentUser.board.organisationId
      ? currentUser.board.organisationId
      : currentUser.board.organisation.id;
    // let url = this.apiServer.metafireSuiteAPI +`/api/claims?boardId=${boardId}&page.limit=${pageSize}&page.offset=${pageIndex}${sortParam}${searchQueryParam}${typeParam}`;
    let url =
      this.apiServer.reportServiceUrl +
      `/report/GetReports?boardId=${boardId}&userId=${userId}&organizationId=${organizationId}&${filter}&${statusParams}`;
    // return this.http.get(url).pipe(
    //   map((response: any) => {
    //     return {
    //       data: response.Data,
    //       meta: response.Meta,
    //     };
    //   })
    // );

    return this.http.get<PagedResponse<ReportModel>>(url).pipe(
      map((res: PagedResponse<ReportModel>) => {
        return res;
      })
    );
  }

  deleteReport(boardId: string, deleteReportId: string): Observable<string> {
    const url = `${this.apiServer.metafireSuiteAPI}/api/reports/${deleteReportId}?boardId=${boardId}`;
    return this.http.delete(url).pipe(
      map(() => 'Report deleted successfully'),
      catchError(() => of('Error deleting report'))
    );
  }
  publishReport(
    boardId: string,
    publishReportId: string,
    publishReporDetails: any
  ): Observable<string> {
    let url = `${this.apiServer.metafireSuiteAPI}/api/reports/${publishReportId}?boardId=${boardId}`;
    return this.http.patch(url, publishReporDetails).pipe(
      map(() => 'Report published successfully'),
      catchError(() => of('Error publishing report'))
    );
  }
  closeReport(
    boardId: string,
    closeReportId: string,
    closeReporDetails: any
  ): Observable<string> {
    let url = `${this.apiServer.metafireSuiteAPI}/api/reports/${closeReportId}/process?boardId=${boardId}`;
    return this.http.patch(url, closeReporDetails).pipe(
      map(() => 'Report closed successfully'),
      catchError(() => of('Error closing report'))
    );
  }
  getArchivingStatusReport(
    boardId: string,
    processReportId: string
  ): Observable<any> {
    const url = `${this.apiServer.metafireSuiteAPI}/api/reports/${processReportId}/archivingStatus?boardId=${boardId}`;
    return this.http.get(url);
  }

  processReport(boardId: string, processReportId: string): Observable<string> {
    const url = `${this.apiServer.metafireSuiteAPI}/api/reports/${processReportId}/process?boardId=${boardId}&closeReport=false`;
    return this.http.patch(url, null).pipe(
      map(() => 'Report processed successfully'),
      catchError(() => of('Error processing report'))
    );
  }

  getReportDetails(boardId: string, editReportId: string): Observable<any> {
    let url =
      this.apiServer.metafireSuiteAPI +
      `/api/reports/${editReportId}?boardId=${boardId}`;
    return this.http.get(url);
  }

  saveEditReport(
    boardId: string,
    editReportId: number,
    editedReportDetails: any
  ): Observable<any> {
    let url =
      this.apiServer.metafireSuiteAPI +
      `/api/reports/${editReportId}?boardId=${boardId}`;
    return this.http.patch(url, editedReportDetails);
  }

  exportReport(
    reportId: string,
    boardId: string,
    exportProcessedReport: boolean
  ): Observable<any> {
    let options = {
      responseType: 'json' as 'blob', // Change responseType to 'json'
    };
    const url =
      this.apiServer.metafireSuiteAPI +
      `/api/reports/${reportId}/export?boardId=${boardId}&exportProcessedReport=${exportProcessedReport}`;

    return this.http.get(url, options).pipe(
      catchError((error: any) => {
        return throwError('Export Failed');
      }),
      map((firstResponse: any) => {
        const jobId = firstResponse.Id;
        return jobId;
      })
    );
  }

  getBackgroundJob(backGroundId: string, boardId: string): Observable<any> {
    const url =
      this.apiServer.metafireSuiteAPI +
      `/api/background-jobs/${backGroundId}?boardId=${boardId}`;
    return this.http.get(url).pipe(
      catchError((error: any) => {
        return throwError('Failed to fetch background job');
      })
    );
  }
}
