import {
  DataTableConfig,
  DefaultDataTableConfig,
} from 'src/shared/models/pagination.model';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from 'src/app/app.config';
import { DisputesServiceService } from 'src/app/disputes/shared/services/disputes.service';
import { SpinnerService } from 'src/core/services/spinner.service';
import { switchMap } from 'rxjs';
import { UserRole } from 'src/core/helpers/user-roles';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-disputes',
  templateUrl: './disputes.component.html',
  styleUrls: ['./disputes.component.scss'],
  providers: [DisputesServiceService],
})
export class DisputesComponent implements OnInit {
  public apiServer = AppConfig.settings.apiServer;
  reportersIconsURL = this.apiServer.reportersIconsUrl;
  disputeIconsURl = this.apiServer.disputeIconsUrl;

  checkRadioButton: any = {};

  dataTableConfig: DataTableConfig = JSON.parse(
    JSON.stringify(DefaultDataTableConfig)
  );
  oldDataTableConfig: DataTableConfig = JSON.parse(
    JSON.stringify(DefaultDataTableConfig)
  );

  openDisputeTable: boolean = true;
  totalItemsOfOpenedData: number = 0;
  totalItemsOfSolvedData: number = 0;
  filteredOpenDisputesData = [];
  filteredSolvedDisputesData = [];
  selectedRow: any;
  currentBoard: string = '';
  currentClaimIdRow: string = '';

  searchQuery: string = '';

  visible: boolean = false;
  currentFirstChildRowOfOpenDispute: any[] = [];
  currentReleasesChildRowOpenDispute: any[] = [];
  searchInputHasValue: boolean = false;
  searchEventOccured: boolean = false;

  currentUser: any;
  // Based on Role
  forMPOAdmin: boolean = false;
  forLabelAdminAndUser: boolean = false;
  activeTab = 0; // Open Dispute tabs

  constructor(
    private disputesService: DisputesServiceService,
    private spinnerService: SpinnerService,
    private router: Router,
    private messageService: MessageService
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser')!);
    this.currentBoard = JSON.parse(
      localStorage.getItem('currentUser')!
    ).board.id;
  }

  ngOnInit() {
    this.getDisputes(false);
    this.getDisputes(true);

    this.adjustBasedOnRole();
  }

  adjustBasedOnRole() {
    let currentUserRole = {
      id: this.currentUser.board.roles[0],

    };
    this.forMPOAdmin = [UserRole.Superadmin].includes(currentUserRole.id);

    this.forLabelAdminAndUser = [
      UserRole.LabelAdmin,
      UserRole.LabelUser,
    ].includes(currentUserRole.id);
  }

  getDisputes(isSolved: boolean = !this.openDisputeTable) {
    this.spinnerService.show();
    this.disputesService
      .getDisputes(
        this.currentBoard,
        this.dataTableConfig.paginationConfig.pageIndex,
        this.dataTableConfig.paginationConfig.pageSize,
        this.dataTableConfig.sortProperty,
        this.searchQuery,
        'OpenDispute',
        isSolved
      )
      .subscribe((response) => {
        if (isSolved) {
          this.filteredSolvedDisputesData = response.data;
          this.totalItemsOfSolvedData = response.meta.count;
        } else {
          this.filteredOpenDisputesData = response.data;
          this.totalItemsOfOpenedData = response.meta.count;
        }
        this.spinnerService.hide();
      });
  }

  changeTable(event:any) {
    let openDisputeTable: boolean = event.originalEvent.target.innerHTML.includes('Open disputes');
    if (openDisputeTable === this.openDisputeTable) return;
    this.openDisputeTable = openDisputeTable;
    this.searchQuery = '';
    [this.dataTableConfig, this.oldDataTableConfig] = [
      this.oldDataTableConfig,
      this.dataTableConfig,
    ];
    this.getDisputes(!openDisputeTable);
  }

  onSort(event: any, isSolved: boolean = !this.openDisputeTable): void {
    if (event && event.field) {
      let sortField = '';
      let sortOrder = '';

      switch (event.field) {
        case 'assignedTo':
          sortField = 'assignedTo';
          break;
        case 'disputes':
          sortField = 'disputes';
          break;
        case 'disputeDate':
          sortField = 'disputeDate';
          break;
        case 'solvedDispute':
          sortField = 'created';
          break;
        default:
          sortField = '-created';
      }

      if (event.order === 1) {
        sortOrder = 'asc';
      } else {
        sortOrder = 'desc';
        sortField = `-${sortField}`;
      }

      if (this.dataTableConfig.sortProperty === sortField) return;
      this.dataTableConfig.sortProperty = sortField;
      this.getDisputes();
    }
  }

  search(event: any, isSearchText: boolean = false): void {
    this.searchQuery = !isSearchText? event.target.value.trim(): event.trim();
    this.searchEventOccured = true;
    this.dataTableConfig.paginationConfig.pageIndex = 0;
    this.getDisputes();
  }

  onPageChange(event: any): void {
    this.dataTableConfig.paginationConfig.pageIndex = event.first;
    this.dataTableConfig.paginationConfig.pageSize = event.rows;
    this.getDisputes();
  }

  showDialog(row: any) {
    this.selectedRow = row; // Assign the selected row data
    this.visible = true;
  }

  expandFirstRowDispute(event: any) {
    this.spinnerService.show();
    this.currentClaimIdRow = event.data.ClaimId;
    this.disputesService
      .expandFirstRowDispute(this.currentBoard, event.data.ClaimId, 0, 50)
      .subscribe((response) => {
        this.currentFirstChildRowOfOpenDispute = response.data;
        this.spinnerService.hide();
      });
  }

  expandReleasesChildRowDispute(rowIndex: any) {
    this.disputesService
      .expandItemRowDispute(
        this.currentBoard,
        this.currentClaimIdRow,
        this.openDisputeTable ? (this.forMPOAdmin? 'Disputes': 'Disputed') : 'Assigned',
        0,
        999,
        rowIndex.Id
      )
      .subscribe((response) => {
        this.currentReleasesChildRowOpenDispute = response.data;
        this.disputesService
          .expandReleasesRowDispute(
            this.currentBoard,
            this.currentClaimIdRow,
            rowIndex.Id,
            this.openDisputeTable ? 'Disputes' : 'Assigned',
            this.currentReleasesChildRowOpenDispute[0].Id
          )
          .subscribe((response) => {
            this.currentReleasesChildRowOpenDispute = response.data;
          });
      });
  }

  expandReleasesChildRowSolvedDispute(rowIndex: any, currentRow: any) {
    this.disputesService
      .expandItemRowDispute(
        this.currentBoard,
        this.currentClaimIdRow,
        this.forMPOAdmin ? "Assigned": (currentRow.IsWin ? 'AssignedToMe' : 'AssignedToOthers'),
        0,
        999,
        rowIndex.Id
      )
      .subscribe((response) => {
        this.currentReleasesChildRowOpenDispute = response.data;
        this.disputesService
          .expandReleasesRowDispute(
            this.currentBoard,
            this.currentClaimIdRow,
            rowIndex.Id,
            currentRow.IsWin ? 'AssignedToMe' : 'AssignedToOthers',
            this.currentReleasesChildRowOpenDispute[0].Id
          )
          .subscribe((response) => {
            this.currentReleasesChildRowOpenDispute = response.data;
          });
      });
  }

  assignDisputedLabel(data: any) {
    let currentSelectedLabel = this.checkRadioButton
    this.visible = false;
    this.spinnerService.show();
    this.disputesService.getDisputeById(this.selectedRow.ClaimId, this.currentBoard)
      .subscribe((claim: any)=>{
      this.disputesService
      .assignDisputedLabel(this.currentBoard, claim, this.currentBoard, currentSelectedLabel)
      .subscribe((response: any) => {
        this.messageService.add({
          severity: 'success',
          detail: `You've successfully assigned the disputed label to ${this.selectedRow.ReleaseName}.`,
          life: 3000,
        });
        this.getDisputes();
        this.spinnerService.hide();
      });
    });

    
  
  }

  getImageURL(data: any) {
    if (data.BoardHasImage)
      return (
        this.disputeIconsURl.replace('{{BoardId}}', data.BoardId) + '-40x40.jpg'
      );
    return 'assets/images/imagePlaceholder.png';
  }

  goToReport(row: any) {
    this.router.navigateByUrl('report-items/' + row.Id + '?status=Disputes');
  }

  onDialogHide() {
    this.checkRadioButton = undefined;
  }

  clearfilter() {
    if (this.searchQuery != '') {
      this.searchQuery = '';
      this.searchInputHasValue = false;
      if (this.searchEventOccured) {
        this.searchEventOccured = false;
        this.getDisputes(!this.openDisputeTable);
      }
    }
  }

  showClearSearchButton() {
    this.searchInputHasValue = true;
  }

  withdrawDispute(data: any) {
    this.disputesService
      .withdrawDispute(this.currentBoard, data)
      .subscribe((response) => {
        this.messageService.add({
          severity: 'success',
          detail: `You've successfully withdrawn from the dispute regarding ${data.ReleaseName}.`,
          life: 3000,
        });
        this.getDisputes(false);
      });
  }

  onSearchChange(searchText: string){
    if(searchText === ""){
      this.getDisputes(!this.openDisputeTable);
    }
  }

  getTooltipContent(data: any) {
    return data.map((x: any) => x.LabelName).join('\n');
  }
}
