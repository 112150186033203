import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FinanceListComponent } from './finance-list/finance-list.component';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from 'src/shared/guards/auth.guard';
import { eligableGuard } from 'src/shared/guards/eligable.guard';
import { UserRole } from 'src/core/helpers/user-roles';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { PaginatorModule } from 'primeng/paginator';
import { DropdownModule } from 'primeng/dropdown';
import { ToolbarModule } from 'primeng/toolbar';
import { FinanceOverviewComponent } from './finance-overview/finance-overview.component';
import { CardModule } from 'primeng/card';
import { TagModule } from 'primeng/tag';
import { ChipModule } from 'primeng/chip';
import { PanelModule } from 'primeng/panel';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { DividerModule } from 'primeng/divider';
import { distributionKeyResolver, distributionReportLabelsResolver, distributionResolver, distributionTracksResolver, incomeStreamsResolver } from './shared/resolvers/finance.resolver';
import { reportsResolver } from '../distributions/shared/resolvers/distribution.resolver';
import { CustomDatePipe } from 'src/core/helpers/custom-pipe/CustomDate.pipe';
import { InputSwitchModule } from 'primeng/inputswitch';

const myroutes: Routes = [
  {
    path: '',
    component: FinanceListComponent,
    canActivate: [authGuard, eligableGuard],
    data: { roles: [UserRole.Superadmin, UserRole.LabelAdmin,UserRole.LabelUser,UserRole.LabelAgent] },
  },
  {
    path: 'overview/:id/:boardId',
    component: FinanceOverviewComponent,
    canActivate: [authGuard, eligableGuard],
    data: { roles: [UserRole.Superadmin, UserRole.LabelAdmin,UserRole.LabelUser,UserRole.LabelAgent] },
    resolve: {
      // distributionTracks: distributionTracksResolver, 
      distribution: distributionResolver,
      incomeStreams: incomeStreamsResolver,
      reporters: reportsResolver,
      distributionKey: distributionKeyResolver,
      distributionReportLabels: distributionReportLabelsResolver
    },
  },
];
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(myroutes),
    TableModule,
    ButtonModule,
    PaginatorModule,
    DropdownModule,
    ToolbarModule,
    CardModule,
    TagModule,
    ChipModule,
    PanelModule,
    BreadcrumbModule,
    DividerModule,
    InputSwitchModule 
  ],
  declarations: [FinanceListComponent, FinanceOverviewComponent, CustomDatePipe],
})
export class FinanceModule { }
