import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AuthenticationService } from 'src/core/services/authentication.service';
import { RefreshTokenService } from 'src/core/services/refresh-token.service';
import { SpinnerService } from 'src/core/services/spinner.service';
import images from '../../assets/json/full_data.json';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-new-signup',
  templateUrl: './new-signup.component.html',
  styleUrls: ['./new-signup.component.scss'],
})
export class NewSignupComponent {
  organizationPrefix: string = 'dk';
  image: any;
  returnUrl!: string;
  userRole!: string;
  board!: string;
  boardId!: string;
  userData!: FormGroup;
  createAccountLoading:boolean=false;

  activeAcceptanceTerm: any;
  showAcceptanceTermsModal: boolean = false;
  htmlContent: string = ``;
  constructor(
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    private spinnerService: SpinnerService,
    private refreshTokenService: RefreshTokenService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.logout();
    this.organizationPrefix = sessionStorage.getItem('organisation') || 'dk';
    let organisationId =
      this.organizationPrefix == 'dk'
        ? '2195ed89-bd12-4e27-9bdf-d8fcd9b8f14d'
        : 'b8f77630-9666-4627-b234-f4a1b5329e17';
      this.authenticationService
      .getActiveAcceptanceService(organisationId)
      .subscribe((response: any) => {
        this.activeAcceptanceTerm = response;
        this.htmlContent = response.text;
      });
  }

  ngOnInit() {
    this.boardId = this.route.snapshot.paramMap.get('boardId')!;
    this.board = this.route.snapshot.queryParamMap.get('LabelName')!;
    this.userRole = this.route.snapshot.queryParamMap.get('Role')!;    
    this.image = images[(Math.random() * images.length) | 0];
    //this.image = images[40];
    this.userData = this.fb.group({
      email: [
        {
          value: this.route.snapshot.queryParamMap.get('email'),
          disabled: true,
        },
      ],
      firstName: [
        null,
        [Validators.required, Validators.pattern(/^[a-zA-ZÆØÅæøå\s]*$/)],
      ],
      lastName: [
        null,
        [Validators.required, Validators.pattern(/^[a-zA-ZÆØÅæøå\s]*$/)],
      ],
      position: [null],
      phoneNumber: [null, [Validators.pattern('^[0-9]+$')]],
      // add acceptTerms as checkbox
      acceptTerms: [false, Validators.requiredTrue],
    });
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.clear();
    this.authenticationService.loggedIn.next(false);
  }

  createUser() {
    this.createAccountLoading=true;
    let email = this.userData.getRawValue().email;
    let user = {
      ...this.userData.getRawValue(),
      invitationId: this.route.snapshot.paramMap.get('invitationId'),
      userRole: this.userRole,
    };
    for (let key in user) if (user[key] === null) delete user[key];
    this.spinnerService.show();
    this.authenticationService.createUser(user).subscribe({
      next: (result: any) => {
        // authenticate user
        this.authenticationService
          .login(email, '{{skip}}-')
          .subscribe((authenticated: any) => {
            this.authenticationService
              .addUserToBoard(
                this.boardId,
                result.data.id,
                email,
                user.firstName + ' ' + user.lastName,
                this.userRole,
                authenticated.access_token
              )
              .subscribe(() => {
                this.spinnerService.hide();
                this.createAccountLoading=false;
                this.messageService.add({
                  severity: 'success',
                  summary: 'Success',
                  detail: `User with email ${email} is created.`,
                });
                // add accept terms to this user
                let userId = result.data.id;
                let activeAcceptanceTermsId = Number(this.activeAcceptanceTerm.id);
                this.authenticationService.acceptUserTerms(userId, activeAcceptanceTermsId).subscribe(() =>{
                  this.messageService.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: `User with email ${email} is created.`,
                  });
  
                  this.router.navigate(['/login'], {
                    queryParams: {
                      email: email,
                      fullName:
                        this.userData.value.firstName +
                        ' ' +
                        this.userData.value.lastName,
                      boardId: this.boardId,
                      Role: this.userRole,
                    },
                  });
                });

                
              });
          });
      },
      error: () => {
        this.spinnerService.hide();
        this.createAccountLoading=false;
        var supportMail = "support@mpo.dk";
        if(this.organizationPrefix == "no")
          {
            supportMail= "support@musicnest.no";
          }
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: `An error occurred during signup. Please contact ${supportMail} for assistance.`,
        });
      },
    });
  }
}
