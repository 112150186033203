import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { environment } from 'src/environments/environment.production';
import { AppConfig } from 'src/app/app.config';
import { UserRole } from 'src/core/helpers/user-roles';
import * as Sentry from "@sentry/angular-ivy";
@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
})
export class MainHeaderComponent {
  items: MenuItem[] | undefined;
  userMenuItems: MenuItem[] | undefined;
  Name!: string;
  currentUser: any;
  currentUserImage: string = '';
  IsLoggedIn: boolean = false;
  showReport: boolean = false;
  showReportItem: boolean = false;
  showDispute: boolean = false;
  showStatistics: boolean = false;
  showAdminTools: boolean = false;
  showUserManagement: boolean = false;
  showLabelManagement: boolean = false;
  showAliasManagement: boolean = false;
  showEmailSender: boolean = false;
  showSubLabels: boolean = false;
  showFinance: boolean = false;
  showDistributions: boolean = false;
  enableSentry: boolean = AppConfig.settings.enableSentry;
  protected isFinanceActiveMPO = AppConfig.settings.isFinanceActiveMPO;
  protected isFinanceActiveMNN = AppConfig.settings.isFinanceActiveMNN;

  currentOrganizationLogo: string = 'assets/images/logo_mpo.svg';

  protected apiServer = AppConfig.settings.apiServer;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public authService: AuthenticationService,
    private translate: TranslateService
  ) {
    this.authService.isLoggedIn.subscribe((res: boolean) => {
      this.IsLoggedIn = res;
    });
    if (sessionStorage.getItem('organisation') == 'no') {
      this.currentOrganizationLogo = 'assets/images/no/logo/logo.svg';
    }
    this.currentUser = JSON.parse(localStorage.getItem('currentUser')!);

    this.translate.setDefaultLang(environment.language1);
    this.translate.use(environment.language1);
    if (this.currentUser != null || '') {
      this.Name =
        this.currentUser.user.firstName + ' ' + this.currentUser.user.lastName;

      if (this.currentUser.user.hasImage)
        this.currentUserImage =
          this.apiServer.awsS3URL +
          `/users/` +
          this.currentUser.user.id +
          `/static/image-190x190.jpg`;
    }
  }
  logout() {
    this.authService.logout();
  }

  adjustTabsBasedOnRoles() {
    let currentUserRole = {
      id: this.currentUser.board.roles[0],
    };

    this.showAdminTools = [
      UserRole.Superadmin,
      UserRole.LabelAdmin,
      UserRole.LabelUser,
      UserRole.LabelAgent,
    ].includes(currentUserRole.id);
    this.showLabelManagement = [
      UserRole.Superadmin,
      UserRole.LabelAdmin,
      UserRole.LabelUser,
      UserRole.LabelAgent,
    ].includes(currentUserRole.id);
    this.showUserManagement = this.showAliasManagement = [
      UserRole.Superadmin,
      UserRole.LabelAdmin,
    ].includes(currentUserRole.id);

    this.showEmailSender = [UserRole.Superadmin].includes(currentUserRole.id);

    this.showSubLabels =
      currentUserRole.id == UserRole.LabelAdmin ||
      currentUserRole.id == UserRole.LabelAgent ||
      currentUserRole.id == UserRole.LabelUser;

    this.showReportItem = [
      UserRole.Superadmin,
      UserRole.LabelAdmin,
      UserRole.LabelUser,
    ].includes(currentUserRole.id);

    this.showReport = this.showStatistics = [
      UserRole.Superadmin,
      UserRole.LabelAdmin,
      UserRole.LabelUser,
      UserRole.LabelAgent,
    ].includes(currentUserRole.id);

    this.showDispute = [
      UserRole.Superadmin,
      UserRole.LabelAdmin,
      UserRole.LabelUser,
      UserRole.LabelAgent,
    ].includes(currentUserRole.id);
    if (
      sessionStorage.getItem('organisation') == 'no' &&
      this.isFinanceActiveMNN
    ) {
      this.showFinance = [
        UserRole.LabelAdmin,
        UserRole.LabelUser,
        UserRole.LabelAgent,
      ].includes(currentUserRole.id);
    }
    if (
      sessionStorage.getItem('organisation') == 'dk' &&
      this.isFinanceActiveMPO
    ) {
      this.showFinance = [
        UserRole.LabelAdmin,
        UserRole.LabelUser,
        UserRole.LabelAgent,
      ].includes(currentUserRole.id);
    }
    this.showDistributions = [UserRole.Superadmin].includes(currentUserRole.id);
  }

  ngOnInit() {
    this.adjustTabsBasedOnRoles();

    // check if currentUser accept cookies or not
    if (localStorage.getItem('currentUser') != null) {
      this.currentUser = JSON.parse(localStorage.getItem('currentUser')!);
      if (this.currentUser.userAcceptanceCookie?.isAccepted == true && this.enableSentry) {

        // initate sentry here
        Sentry.init({
          dsn: environment.sentryDSN,
          integrations: [
            // Registers and configures the Tracing integration,
            // which automatically instruments your application to monitor its
            // performance, including custom Angular routing instrumentation
            Sentry.browserTracingIntegration(),
            // Registers the Replay integration,
            // which automatically captures Session Replays
            Sentry.replayIntegration(),
          ],

          // Set tracesSampleRate to 1.0 to capture 100%
          // of transactions for performance monitoring.
          // We recommend adjusting this value in production
          tracesSampleRate: 1.0,

          // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: environment.traceUrls,

          // Capture Replay for 10% of all sessions,
          // plus for 100% of sessions with an error
          replaysSessionSampleRate: 0.1,
          replaysOnErrorSampleRate: 1.0,
        });
      }
    }

    this.userMenuItems = [
      {
        label: this.Name,
        //icon: 'pi pi-user',
        items: [
          {
            label: 'Profile settings',
            command: () => {
              this.router.navigate(['/profile-settings']);
            },
          },
          {
            label: 'Sign out',
            command: () => {
              this.logout();
            },
          },
        ],
      },
    ];

    this.items = [
      {
        label: 'Reports',
        routerLink: ['/reports'],
        command: () => {
          this.activateAdminTab();
        },
        visible: this.showReport,
      },
      {
        label: 'Report Items',
        routerLink: ['/report-items'],
        command: () => {
          this.activateAdminTab();
        },
        visible: this.showReportItem,
      },
      {
        label: 'Disputes',
        routerLink: ['/disputes'],
        command: () => {
          this.activateAdminTab();
        },
        visible: this.showDispute,
      },
      {
        label: 'Finance',
        routerLink: ['/finance'],
        command: () => {
          this.activateAdminTab();
        },
        visible: this.showFinance,
      },
      {
        label: 'Distributions',
        routerLink: ['/distributions'],
        command: () => {
          this.activateAdminTab();
        },
        visible: this.showDistributions,
      },
      {
        label: 'Statistics',
        routerLink: ['/statistics'],
        command: () => {
          this.activateAdminTab();
        },
        visible: this.showStatistics,
      },
      {
        label:
          this.currentUser.board.roles[0] == UserRole.Superadmin
            ? 'Admin Tools'
            : 'My Label',
        visible: this.showAdminTools,
        command: () => {
          this.activateAdminTab();
        },
        items: [],
      },
    ];

    if (this.showSubLabels) {
      this.items
        .find((x) => x.label == 'Admin Tools' || x.label == 'My Label')
        ?.items?.push({
          label: this.currentUser.board.title,
          icon: '',
          visible: this.showSubLabels,
          styleClass: 'active-label',
        });
      this.items
        .find((x) => x.label == 'Admin Tools' || x.label == 'My Label')
        ?.items?.push({
          separator: true,
        });
    }
    if (this.showAdminTools) {
      this.items
        .find((x) => x.label == 'Admin Tools' || x.label == 'My Label')
        ?.items?.push(
          {
            label: 'User Management',
            routerLink: ['/user-management'],
            command: () => {
              this.activateAdminTab();
            },
            visible: this.showUserManagement,
          },
          {
            label: 'Alias Management',
            routerLink: ['/alias-management'],
            command: () => {
              this.activateAdminTab();
            },
            visible: this.showAliasManagement,
          }
        );
    }

    if (this.showAdminTools) {
      this.items
        .find((x) => x.label == 'Admin Tools' || x.label == 'My Label')
        ?.items?.push(
          {
            separator: true,
            visible:
              this.currentUser.board.roles[0] == UserRole.Superadmin ||
              (this.currentUser.boardPermissions.length > 1 &&
                this.showLabelManagement),
          },
          {
            label: 'View all labels',
            routerLink: ['/label-management'],
            command: () => {
              this.activateAdminTab();
            },
            visible:
              this.currentUser.board.roles[0] == UserRole.Superadmin ||
              (this.currentUser.boardPermissions.length > 1 &&
                this.showLabelManagement),
          },
          {
            label: 'E-mail Sender',
            routerLink: ['/emailsender'],
            command: () => {
              this.activateAdminTab();
            },
            visible: this.showEmailSender,
          }
        );
    }
  }

  ngAfterViewInit() {
    this.activateAdminTab();
  }

  activateAdminTab() {
    const reportsTab = document.getElementById('mainNavBar_4')!;
    if (
      (window.location.href.includes('label-management') ||
        window.location.href.includes('alias-management') ||
        window.location.href.includes('user-management') ||
        window.location.href.includes('emailsender')) &&
      reportsTab
    ) {
      reportsTab.classList.add('p-menuitem-link-active');
    } else if (reportsTab) {
      reportsTab.classList.remove('p-menuitem-link-active');
    }
  }
}
