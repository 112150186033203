import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppConfig } from '../../../app.config';

@Injectable({
  providedIn: 'root',
})
export class DisputesServiceService {
  protected apiServer = AppConfig.settings.apiServer;

  constructor(private http: HttpClient) { }

  getDisputes(boardId: string, pageIndex: number, pageSize: number,
    sortedBy: string = '-disputeDate', searchQuery: string, type: string = 'OpenDispute', isSolved: boolean = false): Observable<any> {

    let sortParam = sortedBy ? `&sort=${sortedBy}` : `&sort=-disputeDate`;
    let searchQueryParam = searchQuery ? `&filter.search=${searchQuery}` : '&filter.search=';
    let typeParam = type ? `&type=${type}` : `&type=OpenDispute`;

    let url =
      this.apiServer.metafireSuiteAPI +
      `/api/claims?boardId=${boardId}&page.limit=${pageSize}&page.offset=${pageIndex}${sortParam}${searchQueryParam}${typeParam}`;

    if (isSolved) {
      url = url.replace('OpenDispute', 'SovledDispute');
      url = url.replace('-disputeDate', '-created');
    }

    return this.http.get(url).pipe(
      map((response: any) => {
        return {
          data: response.Data,
          meta: response.Meta,
        };
      })
    );
  }

  expandFirstRowDispute(boardId: string, claimId: string, pageIndex: number, pageSize: number = 50) {
    let statusesParam = `&filter.statuses=Open,Unpublished`;
    let claimParam = `&filter.claimIds=${claimId}`;

    let url =
      this.apiServer.metafireSuiteAPI +
      `/api/reports?boardId=${boardId}&isCountersRequired=false&page.limit=${pageSize}&page.offset=${pageIndex}${claimParam}${statusesParam}`;

    return this.http.get(url).pipe(map((response: any) => {
      return {
        data: response.Data,
        meta: response.Meta,
      };
    })
    );
  }

  expandItemRowDispute(boardId: string, claimId: string, status: string, pageIndex: number, pageSize: number = 999, reportItemId: string) {
    let statusesIdParam = `&filter.status=${status}`;
    let claimIdParam = `&filter.claimIds=${claimId}`;
    let reportIdParam = `&reportId=${reportItemId}`;

    let url =
      this.apiServer.metafireSuiteAPI +
      `/api/reports/${reportItemId}/items?boardId=${boardId}${claimIdParam}${statusesIdParam}&page.limit=${pageSize}&page.offset=${pageIndex}${reportIdParam}`;

    return this.http.get(url).pipe(map((response: any) => {
      return {
        data: response.Data,
        meta: response.Meta,
      };
    })
    );
  }

  expandReleasesRowDispute(boardId: string, claimId: string, reportersIdParam: string, statusesIdByRow: string = 'Disputes', reportItemId: string
  ): Observable<any> {

    let statusesIdParam = `&filter.status=${statusesIdByRow}`;
    let claimIdParam = `&filter.claimIds=${claimId}`;
    let reporterParam = `&filter.reports=${reportersIdParam}`;
    let reportItemParam = `&reportItemId=${reportItemId}`;

    let url =
      this.apiServer.metafireSuiteAPI +
      `/api/reportItems/${reportItemId}/releases?boardId=${boardId}${claimIdParam}${reporterParam}${statusesIdParam}${reportItemParam}`;

    return this.http.get(url).pipe(map((response: any) => {
      return {
        data: response.Data,
        meta: response.Meta,
      };
    })
    );
  }


  withdrawDispute(currentBoardId: string, data: any) {
    let url = this.apiServer.metafireSuiteAPI + `/api/claims/${data.ClaimId}/disputemembers/${data.Id}?boardId=${currentBoardId}`;
    return this.http.delete(url, data);
  }

  getClaimById(claimId: string, boardId: string) {
    let url = this.apiServer.metafireSuiteAPI + `/api/claims/${claimId}?boardId=${boardId}`;
    return this.http.get(url);
  }

  assignDisputedLabel(currentBoard: any, currentClaim: any, boardId: string, selectedLabel: any){
    let payload: any = {
      Id: currentClaim.Id,        
      ReleaseId: currentClaim.ReleaseId,
      BoardIds: [
        selectedLabel.BoardId
      ],
      Status: "Active"
       
      
    }
    let url = this.apiServer.metafireSuiteAPI + `/api/claims/?boardId=${boardId}`;

    return this.http.patch(url, payload);

  }

  getDisputeById(claimId: string, boardId: string) {
    let url = this.apiServer.metafireSuiteAPI + `/api/claims/${claimId}?boardId=${boardId}`;
    return this.http.get(url);
  }
}
