<div class="card flex justify-content-center">
      <div style="margin-bottom: 10px;">{{label}}</div>

      <p-calendar [(ngModel)]="date" [showIcon]="true" [placeholder]="'mm/dd/yyyy'" [iconAriaLabel]="'ghghg'"></p-calendar>
</div>


<!--        how to use the component in the html files

                <app-datepicker [label]="'Date picker'"></app-datepicker>


-->
