import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from '../../core/services/authentication.service';
import { map, take } from 'rxjs';

export const eligableGuard: CanActivateFn = (route, state) => {
  let roles = route.data.roles as Array<string>;

  const currentUser = JSON.parse(localStorage.getItem('currentUser')!);
  let currentUserRole = {
    id: currentUser.board.roles[0],
  };

  const router = inject(Router);
  if (!roles.includes(currentUserRole.id)) {
    router.navigate(['/reports']);
    return false;
  }
  return true;
};
