import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
})
export class InputTextComponent {
  @Input() icon :string =  '';
  @Input() showIcon : boolean = false;
  @Input() showLength : boolean = false;
  @Input() label!: string
  @Input() value: string = ''
  @Input() minlength: number = 1;
  @Input() maxlength: number = 200;
  @Input() placeholder: string = '';
  
}
