import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss']
})
export class ChipsComponent {
  @Input() label!: string
  values: string[] | undefined;

  separatorExp: any = /,| /;
}
