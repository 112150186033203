import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AuthenticationService } from 'src/core/services/authentication.service';
import { AppConfig } from '../app.config';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
    forgotPasswordForm!: FormGroup;
    loading = false;
    public config = AppConfig.settings;
    constructor(private formBuilder: FormBuilder, private router: Router,
        private authenticationService: AuthenticationService,
        private messageService: MessageService) { }

    ngOnInit(): void {
        this.forgotPasswordForm = this.formBuilder.group({
            email: ['', Validators.required]
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.forgotPasswordForm.controls; }
    onSubmit() {
        // stop here if form is invalid
        if (this.forgotPasswordForm.invalid) {
            return;
        }

        this.loading = true;

        this.authenticationService.forgotPassword(this.f.email.value)
            .subscribe({
                next: () => {
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Email sent',
                        detail: `Please check your inbox for the "Reset Password" email.
                    If you don't see the email, check other places it might be, 
                    like your junk, spam, social or other folders.`,

                    });
                    setTimeout(() => {
                        this.authenticationService.logout();
                    }, this.config.messageNotificationsDelay);
                },
                error: error => {
                    this.loading = false;
                }
            });
    }

}
