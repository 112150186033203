<p-card>
    <p class="card-title">Change your password?</p>
    <p>Please create a strong password
        <i class="fa fa-info-circle" aria-hidden="true" pTooltip="<div>
            <h6 style='font-weight:bold;margin-bottom:-2%;margin-top:-2%'>Password requirements</h6>
            <p class='mb-0'>- Min. 8 characters</p>
            <p class='mb-0'>- Min. 1 uppercase & 1 lowercase letter</p>
            <p class='mb-0'>- Min. 1 number</p>
            <p class='mb-0'>- Min. 1 special character ($,@,!,%,*,?,&)</p>
        </div>" [escape]="false"></i>
    </p>
    <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()" class="p-fluid">
        <div>
            <span class="p-input-icon-left p-input-icon-right">
                <i class="fa fa-lock"></i>
                <input pInputText [type]=" showPassword ? 'text' : 'password' " placeholder="Type new password"
                    formControlName="newPassword" [ngClass]="{ 'is-invalid': submitted && f.newPassword.errors }" />
                <i [ngClass]="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"
                    (click)="showPassword = !showPassword"></i>
            </span>
            <div style="width: max-content;">
                <p *ngIf="submitted && f.newPassword.hasError('required')" class="input-error">You need to input
                    your new password.</p>
                <p class="input-error" *ngIf="submitted && f.newPassword.hasError('minlength')">Password length
                    must be more than or equal 8 characters.</p>
                <p class="input-error" *ngIf="submitted && f.newPassword.hasError('pattern')">Password must
                    contain uppercase, number and one special character.</p>
            </div>
        </div>

        <div class="my-2">
            <span class="p-input-icon-left p-input-icon-right">
                <i class="fa fa-lock"></i>
                <input pInputText id="newRepeatedPassword" [type]=" showRepeatedPassword ? 'text' : 'password' "
                    placeholder="Type password again" formControlName="newRepeatedPassword" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.newRepeatedPassword.errors }" />
                <i [ngClass]="showRepeatedPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"
                    (click)="showRepeatedPassword = !showRepeatedPassword"></i>
            </span>
            <div style="width: max-content;">
                <p *ngIf="submitted && f.newRepeatedPassword.hasError('required')" class="input-error">You need to input
                    your new password again.</p>
                <p *ngIf="f.newRepeatedPassword.hasError('passwordMismatch')" class="input-error">Your Passwords do not
                    match.</p>
            </div>
        </div>

        <p-button type="submit" [loading]="loading" label="Reset Password" styleClass="p-button-success"
            class="w-100" />
    </form>
</p-card>