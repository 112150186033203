<div style="margin-bottom: 10px;">{{label}}</div>
<div class="field-checkbox-container" [ngClass]="{'horizontal': direction === 'horizontal', 'vertical': direction === 'vertical'}">
  <div *ngFor="let category of categories" class="field-checkbox">
    <p-checkbox name="group" [value]="category" [(ngModel)]="selectedCategories" [inputId]="category.key"></p-checkbox>
    <label style="margin-left: 10px;" [for]="category.key">{{ category.name }}</label>
  </div>
</div>



<!--

  <app-check-box 
 [label]="''"
 direction="horizontal" 
 [categories]="categories"  >
</app-check-box>
 -->
