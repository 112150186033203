import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AuthenticationService } from 'src/core/services/authentication.service';
import { RefreshTokenService } from 'src/core/services/refresh-token.service';
import { SpinnerService } from 'src/core/services/spinner.service';
import images from '../../assets/json/full_data.json';
import { environment } from 'src/environments/environment';
import * as Sentry from "@sentry/angular-ivy";
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from '../app.module';
import { AppConfig } from '../app.config';

@Component({
  selector: 'app-new-login',
  templateUrl: './new-login.component.html',
  styleUrls: ['./new-login.component.scss'],
})
export class NewLoginComponent {
  organizationPrefix: string = 'dk';
  email!: string;
  codeDelivered = false;
  pinCode = '';
  pinCodeLength = 4;
  wrongCode = false;
  counter = 0;
  otpValidityInSeconds = 0;
  image: any;
  wrongCodeCounter = 0;
  returnUrl!: string;

  fullName = '';
  userRole!: string;
  boardId!: string;

  activeAcceptanceTerms: any = {};
  activeCookieTerms: any = {};
  showAcceptanceTermsModal: boolean = false;
  showAcceptanceCookieModal: boolean = false;
  htmlContent: string = ``;
  cookieHtmlContent: string = ``;
  enableSentry: boolean =  AppConfig.settings.enableSentry;
  constructor(
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    private spinnerService: SpinnerService,
    private refreshTokenService: RefreshTokenService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    //this.logout();
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.clear();
    this.authenticationService.loggedIn.next(false);
  }

  ngOnInit() {
    this.organizationPrefix = sessionStorage.getItem('organisation') || 'dk';
    this.image = images[(Math.random() * images.length) | 0];
    //this.image = images[40];
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    if (this.route.snapshot.queryParams['email']) {
      this.codeDelivered = true;
      this.email = this.route.snapshot.queryParams['email'];
      this.fullName = this.route.snapshot.queryParams['fullName'];
      this.userRole = this.route.snapshot.queryParams['Role'];
      this.boardId = this.route.snapshot.queryParams['boardId'];
      this.sendOTPCode();
    }


    // check if there is a logged in user with valid otp
    if (localStorage.getItem('currentUser')) {
      let currentUser = JSON.parse(localStorage.getItem('currentUser')!);
      if (currentUser.authenticated && currentUser.authenticated.access_token) {
        // navigate to reports
        this.router.navigate([this.returnUrl + '/reports']).then(() => {});
        
      }
    }
  }

  sendOTPCode() {
    // API Call -- Send code to email
    this.spinnerService.show();
    this.authenticationService
      .sendOTP(this.email, this.organizationPrefix)
      .subscribe(
        (response: any) => {
          this.otpValidityInSeconds = 900;
          this.counter = 60;
          this.codeDelivered = true;
          this.codeCounter();
          this.messageService.add({
            severity: 'success',
            summary: 'Email Sent',
            detail:
              'An email has been sent to your email address. Please check your inbox.',
            life: 3000,
          });
          this.spinnerService.hide();
        },
        (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail:
              'An error occurred while sending the code. Please try again.',
            life: 3000,
          });
          this.spinnerService.hide();
        }
      );
  }

  validateOTP() {
    if (this.pinCode.length < 4) return;
    // API Call -- Verify code
    this.spinnerService.show();
    this.authenticationService
      .validateOTP(this.email, this.organizationPrefix, this.pinCode)
      .subscribe(
        (response: any) => {
          this.messageService.add({
            severity: 'success',
            summary: 'Login verified',
            detail: 'You have been successfully logged in.',
            life: 3000,
          });
          this.getBoardPermissions(response);
        },
        (error) => {
          this.spinnerService.hide();
          this.wrongCode = true;
          this.pinCode = '';
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'The code you entered is incorrect. Please try again.',
            life: 3000,
          });
        }
      );
  }

  addUserToBoard(userId: string, accessToken: string) {
    return this.authenticationService.addUserToBoard(
      this.boardId,
      userId,
      this.email,
      this.fullName,
      this.userRole,
      accessToken
    );
  }

  getBoardPermissions(response: any) {
    this.authenticationService
      .getCurrentBoardPermissions(
        response.currentUser.authenticated.access_token
      )
      .subscribe((currentBoardsPermissions: any) => {
        let currentUser = {
          ...response.currentUser,
          boardPermissions: currentBoardsPermissions,
        };

        // get email from url
        if (this.route.snapshot.queryParams['email']) {
          this.authenticationService
            .getCurrentBoard(
              this.boardId,
              currentUser.authenticated.access_token
            )
            .subscribe((currentBoard: any) => {
              currentUser = {
                ...currentUser,
                board: currentBoard,
              };

              localStorage.setItem('currentUser', JSON.stringify(currentUser));

              this.authenticationService.setLoggedIn(true);
              this.spinnerService.hide();

              // Set the interval time in milliseconds (e.g., 5000 milliseconds for 5 seconds)
              // Start the API calls using the service
              this.refreshTokenService.setApiInterval();
              this.router
                .navigate([this.returnUrl + '/reports'])
                .then(() => {});
            });
        } else {
          localStorage.setItem('currentUser', JSON.stringify(currentUser));
          this.spinnerService.hide();
          // check if user accept the active terms
          if (
            currentUser.userAcceptanceTermsDetails
              ?.isUserAcceptanceTermsAccepted
          ) {
            if(currentUser.userAcceptanceCookie == null)
            {
              this.getActiveCookieTerms();
            } else
            {
              this.navigateToReports();
            }
           
          } else {
            let organisationId =
              this.organizationPrefix == 'dk'
                ? '2195ed89-bd12-4e27-9bdf-d8fcd9b8f14d'
                : 'b8f77630-9666-4627-b234-f4a1b5329e17';
            this.authenticationService
              .getActiveAcceptanceService(organisationId)
              .subscribe((response: any) => {
                this.activeAcceptanceTerms = response;
                this.htmlContent = response.text;
                this.showAcceptanceTermsModal = true;
              });
          }
       
        }
      });
  }

  codeCounter() {
    let intervalId = setInterval(() => {
      this.counter--;
      if (this.counter === 0) {
        this.counter = this.otpValidityInSeconds;
        clearInterval(intervalId);
      }
    }, 1000);
  }

  goBack() {
    this.counter = 0;
    this.wrongCode = false;
    this.codeDelivered = false;
  }

  submitAcceptanceTerms() {
    let userId = JSON.parse(localStorage.getItem('currentUser')!).user.id;
    let activeAcceptanceTermsId = Number(this.activeAcceptanceTerms.id);
    this.authenticationService
      .acceptUserTerms(userId, activeAcceptanceTermsId)
      .subscribe((response: any) => {
this.getActiveCookieTerms();
        
      });
  }

  getActiveCookieTerms()
  {
    let organisationId =
    this.organizationPrefix == 'dk'
      ? '2195ed89-bd12-4e27-9bdf-d8fcd9b8f14d'
      : 'b8f77630-9666-4627-b234-f4a1b5329e17';
  this.authenticationService
    .getActiveCookieTermsService(organisationId)
    .subscribe((response: any) => {
      this.showAcceptanceTermsModal=false;
      this.activeCookieTerms = response;
      this.cookieHtmlContent = response.text;
        this.showAcceptanceCookieModal = true;
    });
  }
  submitAcceptanceCookies() {
    let userId = JSON.parse(localStorage.getItem('currentUser')!).user.id;
    let activeAcceptanceCookieId = Number(this.activeCookieTerms.id);
    this.authenticationService
      .acceptUserCookie(userId, activeAcceptanceCookieId, true)
      .subscribe((response: any) => {
        if(this.enableSentry) {
          Sentry.init({
            dsn: environment.sentryDSN,
            integrations: [
              // Registers and configures the Tracing integration,
              // which automatically instruments your application to monitor its
              // performance, including custom Angular routing instrumentation
              Sentry.browserTracingIntegration(),
              // Registers the Replay integration,
              // which automatically captures Session Replays
              Sentry.replayIntegration(),
            ],
          
            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0,
          
            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: environment.traceUrls,
          
            // Capture Replay for 10% of all sessions,
            // plus for 100% of sessions with an error
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
          });
        }
        this.navigateToReports();
      });
  }

  declineAcceptanceCookies() {
    let userId = JSON.parse(localStorage.getItem('currentUser')!).user.id;
    let activeAcceptanceCookieId = Number(this.activeCookieTerms.id);
    this.authenticationService
      .acceptUserCookie(userId, activeAcceptanceCookieId, false)
      .subscribe((response: any) => {
        this.navigateToReports();
      });
  }

  navigateToReports() {
    this.authenticationService.setLoggedIn(true);
    // Set the interval time in milliseconds (e.g., 5000 milliseconds for 5 seconds)
    // Start the API calls using the service
    this.refreshTokenService.setApiInterval();
    this.router.navigate([this.returnUrl + '/reports']).then(() => {});
  }

  cancelAcceptanceTerms() {
    this.logout();
    this.showAcceptanceTermsModal = false;
    this.goBack();
    
  }
}
