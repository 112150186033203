import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from '../../core/services/authentication.service';
import { map, take } from 'rxjs';

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthenticationService);
  const router = inject(Router);
  return authService.isLoggedIn // {1}
    .pipe(
      take(1), // {2}
      map((isLoggedIn: boolean) => {
        // {3}
        if (!isLoggedIn && !localStorage.getItem('currentUser')) {
          localStorage.clear();          
          router.navigate(['/login']); // {4}  
          authService.setLoggedIn(false);              
          return false;
        }
        return true;
      })
    );
  // if (localStorage.getItem('currentUser')) {
  //   return true;
  // }

  // // Redirect to the login page
  // authService.currentUser = null;
  // return router.parseUrl('/login');
};
