import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from 'src/shared/guards/auth.guard';
import { eligableGuard } from 'src/shared/guards/eligable.guard';
import { UserRole } from 'src/core/helpers/user-roles';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { PaginatorModule } from 'primeng/paginator';
import { DropdownModule } from 'primeng/dropdown';
import { ToolbarModule } from 'primeng/toolbar';
import { DistributionsListComponent } from './distributions-list/distributions-list.component';
import { MenuModule } from 'primeng/menu';
import { CreateDistributionComponent } from './create-distribution/create-distribution.component';
import { CardModule } from 'primeng/card';
import { SharedModule } from 'primeng/api';
import { PanelModule } from 'primeng/panel';
import { ReactiveFormsModule } from '@angular/forms';
import { DividerModule } from 'primeng/divider';
import { ChipsModule } from 'primeng/chips';
import { FileUploadModule } from 'primeng/fileupload';
import { InputSwitchModule } from 'primeng/inputswitch';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { AnimateModule } from 'primeng/animate';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { distributionResolver, reportsResolver } from './shared/resolvers/distribution.resolver';
import { ControlCenterComponent } from './control-center/control-center.component';
import { TagModule } from 'primeng/tag';
import { BadgeModule } from 'primeng/badge';
import { ConfirmationService } from 'primeng/api';
import { InputNumberModule } from 'primeng/inputnumber';
import { CustomDatetimePipe } from 'src/core/helpers/custom-pipe/CustomDatetimePipe.pipe';
const myroutes: Routes = [
  {
    path: '',
    component: DistributionsListComponent,
    canActivate: [authGuard, eligableGuard],
    data: { roles: [UserRole.Superadmin] },
  },
  {
    path: 'create/:id',
    component: CreateDistributionComponent,
    canActivate: [authGuard, eligableGuard],
    data: { roles: [UserRole.Superadmin] },
    resolve: { distribution: distributionResolver, reporters: reportsResolver },
  },
  {
    path: 'control-center/:id',
    component: ControlCenterComponent,
    canActivate: [authGuard, eligableGuard],
    data: { roles: [UserRole.Superadmin] }
  }
];
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(myroutes),
    ReactiveFormsModule,
    TableModule,
    ButtonModule,
    PaginatorModule,
    DropdownModule,
    ToolbarModule,
    MenuModule,
    CardModule,
    SharedModule,
    PanelModule,
    DividerModule,
    ChipsModule,
    FileUploadModule,
    InputSwitchModule,
    RadioButtonModule,
    InputTextareaModule,
    AnimateModule,
    ConfirmDialogModule,
    ToastModule,
    TooltipModule,
    TagModule,
    BadgeModule,
    InputNumberModule,
    
  ],
  declarations: [DistributionsListComponent, CreateDistributionComponent, ControlCenterComponent,
     CustomDatetimePipe],
  providers: [ConfirmationService]

})
export class DistributionModule { }
