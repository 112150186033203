import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent {
  @Input() label!: string
  @Input() value: string = '';
  @Input() feedback: boolean = true;
  @Input() toggleMask: boolean = true;

  
}
