<div class=" flex justify-content-center">
      <div style="margin-bottom: 10px;">{{label}}</div>
  <p-calendar
             label="Agree to Terms"
              [(ngModel)]="date" [showTime]="true"
               [showSeconds]="true" [placeholder]="placeholder"
               [showTime]="showTime"
                [timeOnly]="timeOnly">
  </p-calendar>
</div>

<!--        how to use the component in the html files

            <app-date-time-picker [label]="'Datetime picker'"></app-date-time-picker>


-->
