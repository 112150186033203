<p-menubar [model]="items" id="mainNavBar">
  <ng-template pTemplate="start">
    <img src="{{currentOrganizationLogo}}" height="55" class="ms-2 me-3"/>
  </ng-template>

  <ng-template pTemplate="end">
    <p-menubar #menu [model]="userMenuItems">
      <ng-template pTemplate="end">
        <!-- <img src="assets/images/user.svg" class="user-img-nav" *ngIf="!currentUser.user.hasImage" /> -->
        <img src="{{currentUserImage}}"  class="user-img-nav" *ngIf="currentUser.user.hasImage" />
      </ng-template>
    </p-menubar>
  </ng-template>
</p-menubar>
