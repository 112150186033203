import { Injectable } from '@angular/core';
import { NgProgressRef } from 'ngx-progressbar';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {

  progressRef!: NgProgressRef;
  pendingRequests = 0;

  constructor() {
    
  }

  show() {
    this.pendingRequests++;
    this.progressRef.start();
  }

  hide() {
    this.pendingRequests--;
    if (this.pendingRequests < 1) {
      this.pendingRequests = 0;
      this.progressRef.complete();
    }
  }
}
