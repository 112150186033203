<div class="card p-fluid">
      <div style="margin-bottom: 10px;">{{label}}</div>

  <p-chips [(ngModel)]="values" [separator]="separatorExp" placeholder="Hint: a, b c"></p-chips>
</div>

  <!--      how to use the component in the html files

            <app-chips  [label]="'hobbies'"></app-chips>

-->
