import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FileUpload } from 'primeng/fileupload';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MenuItem, MessageService } from 'primeng/api';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { Router } from '@angular/router';
import { AppConfig } from 'src/app/app.config';
import { SpinnerService } from 'src/core/services/spinner.service';

@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrls: ['./profile-settings.component.scss'],
})
export class ProfileSettingsComponent implements OnInit {
  @ViewChild('fileUpload', { static: false }) FileUpload!: FileUpload;

  uploadedImage!: string;
  ProfileForm!: FormGroup;
  uploadedFile: any;
  IsNewImageUploaded: boolean = false;
  public config = AppConfig.settings;

  userdata: any = JSON.parse(localStorage.getItem('currentUser')!);
  user = this.userdata.user;
  isdisable!: boolean;
  profileImage: any;
  loading: boolean = false;
  constructor(
    private fb: FormBuilder,
    private AuthenticationService: AuthenticationService,
    private router: Router,
    private messageService: MessageService,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit() {
    this.AuthenticationService.isProfileSettings.next(true);
    this.initForms();
  }

  initForms() {
    this.ProfileForm = this.fb.group({
      FirstName: [this.user.firstName, [Validators.required, Validators.pattern(/^[a-zA-ZÆØÅæøå\s]*$/)]],
      LastName: [this.user.lastName, [Validators.required, Validators.pattern(/^[a-zA-ZÆØÅæøå\s]*$/)]],
      Email: [this.user.email, Validators.required],
      PhoneNumber: [this.user.phoneNumber],
      JobTitle: [this.user.position],
      hasImage: [this.user.hasImage],
    });

    if (this.user.hasImage) {
      this.uploadedImage =
        this.config.apiServer.awsS3URL +
        `/users/` +
        this.user.id +
        `/static/image-190x190.jpg`;
    }
  }

  onCustomLinkClick() {
    this.FileUpload.choose();
  }

  onSelectImage(file: any) {
    this.uploadedFile = file;
    this.AuthenticationService.imageUploadRequest(file).subscribe(
      (res: any) => {
        this.IsNewImageUploaded = true;
        this.uploadedImage = res.Uri;
      }
    );
  }

  onchange() {
    if (this.ProfileForm.valid) {
      this.isdisable = false;
    } else {
      this.isdisable = true;
    }
  }

  submitForm() {
    this.spinnerService.show();
    this.loading = true;
    this.AuthenticationService.submitProfileForm(
      this.userdata,
      this.ProfileForm,
      this.uploadedImage,
      this.IsNewImageUploaded
    ).subscribe((res: any) => {
      this.AuthenticationService.getCurrentUserDetails(
        this.userdata.authenticated.access_token
      ).subscribe((currentUser: any) => {
        // update user in localStorage
        // update user email in the metafire
        this.AuthenticationService.updateMetafireDUserEmail(
          currentUser.id,
          this.ProfileForm.value.Email,
          this.ProfileForm.value.FirstName +
            ' ' +
            this.ProfileForm.value.LastName,
            this.userdata.board.id,
            null
        ).subscribe((res: any) => {
          this.spinnerService.hide();
          this.loading = false;
          this.refreshLocalStorage(currentUser);
          location.reload();
          this.messageService.add({
            severity: 'success',
            summary: 'User Profile',
            detail: `Your profile has been successfully updated.`,
            life: 3000,
          });
        });
      });
    });
  }

  refreshLocalStorage(currentUser: any) {
    let currentUserLocalStoage = this.userdata;
    currentUserLocalStoage.user.firstName = currentUser.firstName;
    currentUserLocalStoage.user.lastName = currentUser.lastName;
    currentUserLocalStoage.user.email = currentUser.email;
    currentUserLocalStoage.user.position = currentUser.position;
    currentUserLocalStoage.user.phoneNumber = currentUser.phoneNumber;
    currentUserLocalStoage.user.hasImage = this.uploadedImage
      ? currentUser.hasImage
      : false;

    localStorage.clear();
    localStorage.setItem('currentUser', JSON.stringify(currentUserLocalStoage));
  }

  routing() {
    this.router.navigate(['/reports']);
  }

  removeImage() {
    this.uploadedImage = '';
    this.AuthenticationService.ProfileImage.next('');
  }
}
