import { Component, Input, Output, EventEmitter  } from '@angular/core';

@Component({
  selector: 'app-drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss'],
})
export class DropDownComponent  {

  @Input() options: any[] = [];
  @Input() selectedOption: any;
  @Input() placeholder: string = '';
  @Input() optionLabel: string = '';
  @Input() isDisabled: boolean = false;
  @Input() label!: string;

  @Output() selectedOptionChange = new EventEmitter<string>();

  onSelectChange() {
    this.selectedOptionChange.emit(this.selectedOption);
  }
}
