import { Component, OnInit, Input } from '@angular/core';

interface City {
  name: string,
  code: string
}


@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss']
})
export class MultiSelectComponent {
  @Input() label!: string;
  @Input() ismultiple!: boolean;
  @Input() options!: object[];

  @Input() selectedOption!: string[];

  constructor() { }

}
