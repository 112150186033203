import { Component, OnInit } from '@angular/core';
import { DistributionService } from '../shared/services/distributions.service';

import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ChangeDetectorRef } from '@angular/core';

import { ConfirmationService } from 'primeng/api';
import { GetDistributionEmailInformation } from '../shared/models/distribution.model';
@Component({
  selector: 'app-control-center',
  templateUrl: './control-center.component.html',
  styleUrls: ['./control-center.component.css'],
})
export class ControlCenterComponent implements OnInit {
  emailErrors: GetDistributionEmailInformation[] = [];
  tempEmailErrors: GetDistributionEmailInformation[] = [];
  successfulEmails: GetDistributionEmailInformation[] = [];
  permanentErrorCount: string = this.emailErrors.length.toString();
  tempErrorsCount: string = this.tempEmailErrors.length.toString();
  succesfulCount: string = this.successfulEmails.length.toString();
  clickedDateTime: string | null = null;
  showTableBadge: boolean = false;
  isApproveDisabled = false;
  isPublishDisabled = true;
  isNotificationDisabled = true;
  isNotificationSent = false;
  distributionId!: string;
  distroTitle: string | null = null;
  private messageLife: number = 9000;
  constructor(
    private distributionService: DistributionService,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private cd: ChangeDetectorRef
  ) {
    this.distributionId = this.route.snapshot.params.id;
  }
  ngOnInit() {
    this.getDistribution();
    
  }

  onApprovedClick() {
    this.isApproveDisabled = true;
    this.isPublishDisabled = false;
    this.distributionService.approveDistribution(this.distributionId).subscribe(
      (res: any) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Distribution has been  approved',
        });
      },
      (error) => {
        console.log(error);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Distribution approval failed',
        });
      }
    );
  }
  onPublishClick() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to publish this distribution?',
      header: 'Confirm Publish',
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: 'Cancel',
      rejectButtonStyleClass: 'p-button-secondary',
      accept: () => {
        this.distributionService
          .publishDistribution(this.distributionId)
          .subscribe(
            (res: any) => {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Distribution has been  published',
                life: 3000,
              });
            },
            (error) => {
              console.log(error);
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Distribution publish failed',
                life: 3000,
              });
            }
          );
        this.isPublishDisabled = true;
        this.isNotificationDisabled = false;
      },
    });
  }

  onSendNotification() {
    if (this.isNotificationSent) {
      this.showTableBadge = true;
      this.getDistributionEmails();
    } else {
      this.confirmationService.confirm({
        message: 'Are you sure you want to send notification?',
        header: 'Confirm Notification',
        acceptButtonStyleClass: 'p-button-danger',
        rejectLabel: 'Cancel',
        rejectButtonStyleClass: 'p-button-secondary',
        accept: () => {
          this.messageService.add({
            severity: 'secondary',
            summary: 'secondary',
            detail: 'Notification emails are being sent',
            life: this.messageLife,
          });
          this.distributionService
            .sendDistributionNotificationEmail(this.distributionId)
            .subscribe(
              (res: any) => {
                this.getDistributionEmails();

                this.messageService.clear();
                this.messageService.add({
                  severity: 'success',
                  summary: 'Success',
                  detail: 'Notification emails have been sent',
                  life: 2000,
                });
              },
              (error) => {
                console.log(error);
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'Notification emails failed to send',
                  life: 2000,
                });
                this.isNotificationDisabled = true;
                this.clickedDateTime = new Date().toLocaleString();
                this.showTableBadge = true;
              }
            );
        },
      });
    }
  }

  getDistributionEmails() {
    this.distributionService
      .getDistributionEmailInformation(this.distributionId)
      .subscribe((res: GetDistributionEmailInformation[]) => {
        res.forEach((emailInfo) => {
         
          if (emailInfo.severity == null) {
            this.successfulEmails.push(emailInfo);
            
          } else if (emailInfo.severity == 'permanent') {
            this.emailErrors.push(emailInfo);
          } else if (emailInfo.severity == 'temporary') {
            this.tempEmailErrors.push(emailInfo);
          }
        });
        this.permanentErrorCount = this.emailErrors.length.toString();
        this.tempErrorsCount = this.tempEmailErrors.length.toString();
        this.succesfulCount = this.successfulEmails.length.toString();
      });
    this.messageLife = 0;
    this.isNotificationDisabled = true;
    this.clickedDateTime = new Date().toLocaleString();
    this.showTableBadge = true;
    this.cd.detectChanges();
  }

  getDistribution() {
    this.distributionService
      .getDistribution(this.distributionId)
      .subscribe((res: any) => {
        this.distroTitle = res.title;
        this.isApproveDisabled = res.isApproved;
        this.isNotificationSent = res.isSent;
        this.isPublishDisabled = res.isPublished  || !res.isApproved;
        this.clickedDateTime = res.sentDate;
        this.showTableBadge = res.isSent;
        this.isNotificationDisabled = !res.isPublished;
        
        if (res.isSent) {
          this.onSendNotification();
        }
        this.cd.detectChanges();
      });
  }

  onExportBankTransferDataClick() {}
  onExportNewBranchesClick() {}

 
}
