import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'CustomDatetime'
})
export class CustomDatetimePipe implements PipeTransform {

  transform(dateString: string): string {
    const date = new Date(dateString);
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const time = `${hours}:${minutes}`
    // Format the date as "Month dayth, year"
    return `${month} ${this.getOrdinalSuffix(day)} ${year}, ${time}`;
  }

  getOrdinalSuffix(day: number): string {
    if (day > 3 && day < 21) return `${day}th`;
    switch (day % 10) {
      case 1: return `${day}st`;
      case 2: return `${day}nd`;
      case 3: return `${day}rd`;
      default: return `${day}th`;
    }
  }
}
