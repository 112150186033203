<div class="pageContainer">
  <div>
    <h2>
      <span class="screen-header">Control Center</span> - {{ this.distroTitle }}
    </h2>
  </div>
  <br />

  <p-panel class="no-header createdistribution">
    <div class="d-flex flex-column gap-3">
      <div>
        <h5 class="fw-bold text-black">1 - Approve Statement</h5>
        <p>Approving will lock the calculations</p>
        <p-button
          label="Approve Statement"
          styleClass="p-button-success"
          [disabled]="isApproveDisabled"
          (onClick)="onApprovedClick()"
        ></p-button>
      </div>
      <hr />
      <div>
        <h5 class="fw-bold text-black">2 - Export Data</h5>
        <p>Select the type of file to export</p>
        <div class="d-flex gap-2">
          <p-button
            label="Export Bank Transfer Data"
            styleClass="p-button-outline"
            [disabled]="true"
            (onClick)="onExportBankTransferDataClick()"
          ></p-button>
          <p-button
            label="Export new branches for withholdings"
            styleClass="p-button-outline"
            [disabled]="true"
            (onClick)="onExportNewBranchesClick()"
          ></p-button>
        </div>
      </div>

      <hr />
      <div>
        <h5 class="fw-bold text-black">3 - Publish and mark as paid</h5>
        <p>
          This will set the transfer Date and grant the involved parties access
          to view their Statement
        </p>
        <p-button
          label="Publish statements and set Transfer Date"
          styleClass="p-button-success"
          [disabled]="isPublishDisabled"
          (onClick)="onPublishClick()"
        ></p-button>
      </div>
      <hr />
      <div>
        <h5 class="fw-bold text-black">
          4- Notification Email Status
          <span style="font-weight: lighter" *ngIf="clickedDateTime"
            >(Triggerd on {{ clickedDateTime | CustomDatetime }})</span
          >
        </h5>
        <p *ngIf="!showTableBadge">
          This will set the transfer Date and grant the involved parties access
          to view their Statement
        </p>
        <p-button
          *ngIf="!showTableBadge"
          (onClick)="onSendNotification()"
          [disabled]="isNotificationDisabled"
          label="Send notifications to subscribed users"
          styleClass="p-button-success"
        ></p-button>
        <br />
        <div *ngIf="showTableBadge">
          <span class="email-errors-badge">
            <p-badge
              badgeSize="Large"
              [value]="permanentErrorCount"
              severity="danger"
              styleClass="mr-2"
            ></p-badge>
            Permenant Errors
          </span>
          <p-table
            *ngIf="emailErrors && emailErrors.length > 0"
            [value]="emailErrors"
          >
            <ng-template pTemplate="header">
              <tr>
                <th>Sent date</th>
                <th>Name</th>
                <th>Email</th>
                <th pSortableColumn="label">
                  Label <p-sortIcon field="label"></p-sortIcon>
                </th>
                <th>Error type</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-error>
              <tr>
                <td>{{ error.createdAt | CustomDatetime }}</td>
                <td>{{ error.userName }}</td>
                <td>{{ error.email }}</td>
                <td>{{ error.labelName }}</td>
                <td>{{ error.reason }}</td>
              </tr>
            </ng-template>
          </p-table>
          <br>
          <span
            class="email-errors-badge"
            *ngIf="tempEmailErrors && tempEmailErrors.length > 0"
          >
            <p-badge
              badgeSize="Large"
              [value]="tempErrorsCount"
              severity="warning"
              styleClass="mr-2"
            ></p-badge>
            Temporary Errors (Updates Every Hour)
          </span>

          <p-table
            *ngIf="tempEmailErrors && tempEmailErrors.length > 0"
            [value]="tempEmailErrors"
          >
            <ng-template pTemplate="header">
              <tr>
                <th>Sent date</th>
                <th>Name</th>
                <th>Email</th>
                <th pSortableColumn="label">
                  Label <p-sortIcon field="label"></p-sortIcon>
                </th>
                <th>Error type</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-tempError>
              <tr>
                <td>{{ tempError.createdAt | CustomDatetime }}</td>
                <td>{{ tempError.userName }}</td>
                <td>{{ tempError.email }}</td>
                <td>{{ tempError.labelName }}</td>
                <td>{{ tempError.reason }}</td>
              </tr>
            </ng-template>
          </p-table>
          <br>
          <span class="email-errors-badge">
            <p-badge
              badgeSize="Large"
              [value]="succesfulCount"
              severity="success"
              styleClass="mr-2"
            ></p-badge>
            Successful sent mails
          </span>
          <p-table
            *ngIf="successfulEmails && successfulEmails.length > 0"
            [value]="successfulEmails"
          >
            <ng-template pTemplate="header">
              <tr>
                <th>Sent date</th>
                <th>Name</th>
                <th>Email</th>
                <th pSortableColumn="label">
                  Label <p-sortIcon field="label"></p-sortIcon>
                </th>
                <th>last status</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-succesful>
              <tr>
                <td>{{ succesful.createdAt | CustomDatetime }}</td>
                <td>{{ succesful.userName }}</td>
                <td>{{ succesful.email }}</td>
                <td>{{ succesful.labelName }}</td>
                <td>{{ succesful.deliveryStatus }}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </p-panel>
</div>
<p-confirmDialog header="publish distribution"></p-confirmDialog>
