import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { FinanceService } from '../services/finance.service';

export const distributionTracksResolver: ResolveFn<any> = (route, state) => {
  const Id = route.params.id;
  const boardId = route.params.boardId;
  return inject(FinanceService).getLabelTracksFromDistributionReports(Id,boardId);
};

export const distributionResolver: ResolveFn<any> = (route, state) => {
  const Id = route.params.id;
  const boardId = route.params.boardId;
  return inject(FinanceService).getDistributionDetails(Id,boardId);
};

export const incomeStreamsResolver: ResolveFn<any> = (route, state) => {
  const Id = route.params.id;
  return inject(FinanceService).getIncomeStreamsSheet(Id);
}

export const distributionKeyResolver: ResolveFn<any> = (route, state) => {
  const Id = route.params.id;
  const boardId = route.params.boardId;
  return inject(FinanceService).getDistributionKey(Id,boardId);
}

export const distributionReportLabelsResolver: ResolveFn<any> = (route, state) => {
  const Id = route.params.id;
  const boardId = route.params.boardId;
  return inject(FinanceService).getDistributionReportLabels(Id);
}
