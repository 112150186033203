import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CheckboxModule } from 'primeng/checkbox';
import { StyleClassModule } from 'primeng/styleclass';
import { CardModule } from 'primeng/card';
import { HomeComponent } from './home/home.component';
import { ButtonModule } from 'primeng/button';
import { AvatarModule } from 'primeng/avatar';
import { InputTextModule } from 'primeng/inputtext';
import { MegaMenuModule } from 'primeng/megamenu';
import { MessagesModule } from 'primeng/messages';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { MenuModule } from 'primeng/menu';
import { PanelMenuModule } from 'primeng/panelmenu';
import { SharedModule } from '../shared/components/shared.module';
import { AppConfig } from './app.config';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { DisputesModule } from './disputes/disputes.module';
import { MainHeaderComponent } from './user/main-header/main-header.component';
import { UserModule } from './user/user.module';
import { AuthInterceptor } from 'src/core/Interceptors/auth.interceptor';
import { ErrorInterceptor } from 'src/core/Interceptors/error.interceptor';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { TooltipModule } from 'primeng/tooltip';
import { RefreshTokenService } from 'src/core/services/refresh-token.service';
import { registerLocaleData } from '@angular/common';
import localeDa from '@angular/common/locales/da';

registerLocaleData(localeDa);
import { NewLoginComponent } from './new-login/new-login.component';
import { NewSignupComponent } from './new-signup/new-signup.component';
import { FinanceModule } from './finance/finance.module';
import { DistributionModule } from './distributions/distributions.module';
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, '../assets/translation/', '.json');
}

export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MainHeaderComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    NewLoginComponent,
    NewSignupComponent
  ],
  providers: [
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      deps: [AppConfig],
      multi: true,
    },
    MessageService,
    RefreshTokenService,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    CheckboxModule,
    StyleClassModule,
    CardModule,
    ButtonModule,
    AvatarModule,
    InputTextModule,
    MegaMenuModule,
    MessagesModule,
    ToastModule,
    MenuModule,
    PanelMenuModule,
    SharedModule,
    DisputesModule,
    UserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    TooltipModule,
    FinanceModule,
    DistributionModule
  ],
})
export class AppModule {}
