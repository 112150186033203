import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisputesComponent } from './disputes-list/disputes.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../shared/components/shared.module';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { ToolbarModule } from 'primeng/toolbar';
import { ToastModule } from 'primeng/toast';
import { PaginatorModule } from 'primeng/paginator';
import { TreeTableModule } from 'primeng/treetable';
import { DialogModule } from 'primeng/dialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { OrganizationTitle } from 'src/core/helpers/organization-helper';

const routes: Routes = [
  {
    path: '',
    component: DisputesComponent,
    title:'Disputes  - ' + (sessionStorage.getItem('organisation') == 'no' ? OrganizationTitle.MusicNest: OrganizationTitle.MPO)
  }
];

@NgModule({
  declarations: [
    DisputesComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    ButtonModule,
    ToolbarModule,
    TreeTableModule,
    ToastModule,
    RadioButtonModule,
    DialogModule,
    PaginatorModule,
    TableModule,
    SharedModule
  ],
  exports: [
    DisputesComponent
  ]

})
export class DisputesModule { }
