
<div class="">
  <p-button [disabled]="isdisabled" label="{{btnlabel}}" styleClass="p-button-{{bordertype}} p-button-{{color}}" icon="{{btnIcon}}" [type]="type" ></p-button>

</div>


  <!--      how to use the component in the html files


<app-button [btnlabel]="'submit'" [bordertype]="'raised'" [color]="'success'" [btnIcon]="'pi pi-check'"></app-button>             using primeng icons

<app-button [btnlabel]="'submit'" [bordertype]="'raised'" [color]="'success'" [btnIcon]="'fa-solid fa-phone'"></app-button>        using fontawesome icons

-->

